import React from 'react';
import { Row, Col, ListGroup } from 'react-bootstrap';
import KPI from '../KPI/Kpi.js';
import { useNavigate } from 'react-router-dom';

const DocumentHighLigth = ({ state, setState, producto }) => {
    const navigate = useNavigate();

    const handleDocumentClick = (documento) => {
        setState({
            ...state,
            producto_para_mostrar: documento,
            consulting: true
        });
        navigate("/documento");
    };

    const handleContenido = (Contenido) => {
        if(Contenido !== null){
            // remover tabulaciones
            Contenido = Contenido.replace(/\t/g, '');
            // remover saltos de línea
            Contenido = Contenido.replace(/\n/g, '');
            // remover espacios dobles
            Contenido = Contenido.replace(/\s\s/g, ' ');
            // remover espacios al inicio
            Contenido = Contenido.replace(/^\s/g, '');
            // remover espacios al final
            Contenido = Contenido.replace(/\s$/g, '');
            // remover etiquetas de html
            Contenido = Contenido.replace(/<[^>]*>?/gm, '');
            // remover espacios de html
            Contenido = Contenido.replace(/&nbsp;/g, '');
            if(Contenido.length > 200) {
                return Contenido.substring(0, 200) + '...';
            } else {
                return Contenido;
            }
        } else {
            return '';
        }
    };

    const Grafica = () => {
        if(state.destacado && producto.JAVASCRIPT !== null){
            let javascript_content = JSON.parse(producto.JAVASCRIPT);
            let kpi = javascript_content.ponderacion;
            // promedio del arreglo kpi
            let kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            return (
                <>
                    <KPI Valor={kpi_average}/>
                </>
            );
        } else {
            return null;
        }
    };

    return(
        <>
            <ListGroup>
                <ListGroup.Item
                    className='hightlight'
                    action
                    onClick={() => handleDocumentClick(producto)}
                    key={"hl_l5_" + producto.ID + "_" + new Date().getTime() + "_" + Math.random()}>
                        <Row>
                            <Col xs={5} lg={2} className='mt-4 mb-4'>
                                {Grafica()}
                            </Col>
                            <Col xs={7} lg={10}>
                                <Row>
                                    <Col xs={12} lg={12}>
                                        <h4>{producto.NOMBRE}</h4>
                                        <p>
                                            {handleContenido(producto.PROSPECCION)}
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} lg={12} className="text-end">
                                        Ver más
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ListGroup.Item>
            </ListGroup>
        </>
    );
};

export {DocumentHighLigth};