import React from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import KPI from '../KPI/Kpi.js';
import axios from 'axios';

const EvaluacionInteres = ({ Documento, state }) => {
    const [show, setShow] = React.useState(false);
    const [contenidoModal, setContenidoModal] = React.useState(null);
    const [tituloModal, setTituloModal] = React.useState('');

    React.useEffect(() => {
        // Reiniciamos el scroll en cuanto se carga la vista correspondiente al producto
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    const makeChart = () => {
        let javascript_content = JSON.parse(Documento.JAVASCRIPT);
        let kpi = javascript_content.ponderacion;
        // promedio del arreglo kpi
        let kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
        return(
            <div className='text-center mb-4 mt-4'>
                <KPI Valor={kpi_average}/>
            </div>
        );
    }

    const evaluacionPdf = () => {
        let url_path = Documento.URL;
        return(<>
                <iframe 
                src={`https://ayplatam.com/Scripts/ViewerJS/#${url_path}`} 
                title={`${Documento.NOMBRE}`} width="100%" height="600" allowFullScreen></iframe>
            </>);
    };

    const handleClose = () => setShow(false);

    async function handleEscenario(escenario) {
        let url = "https://ayplatam.com/api/prod/escenario/" + escenario
        await axios.get(url).then((response) => {
            let respuesta = response.data.data;
            let contenido = respuesta.DESCRIPCION;
            setTituloModal(respuesta.NOMBRE);
            setContenidoModal(
                <>
                    <div style={{ whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{__html: contenido}}></div>
                </>
            );
            setShow(true);
        }).catch((error) => {
            console.log(error);
        });
    }

    // funcion para capturar el click en los links del div con id prospeccion
    const handleProspection = (e) => {
        e.preventDefault();
        let url = e.target.parentElement.href;
        let number = url.split('/')[url.split('/').length - 1];
        handleEscenario(number);
    }

    return(
        <>
            {Documento.URL !== null ? evaluacionPdf() : (
                <Container fluid style={{fontSize: state.font_size}}>
                    <Row className="justify-content-center">
                        <Col xs={6} lg={2} className="text-center">
                            {makeChart()}
                        </Col>
                    </Row>
                    <Row className="resultBelt">
                        <Col className='mb-4 mt-4'>
                            <h3>Resumen</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-4 text-justify">
                            <div className='text-justify' dangerouslySetInnerHTML={{__html: Documento.RESUMEN}}></div>
                        </Col>
                    </Row>
                    {Documento.CONTENIDO !== null && Documento.CONTENIDO !== "<p><br></p>" ? (
                    <Row className="resultBelt">
                        <Col className='mb-4 mt-4'>
                            <h3>Conclusiones del &Aacute;rea</h3>
                        </Col>
                    </Row>
                    ) : null}
                    {Documento.CONTENIDO !== null && Documento.CONTENIDO !== "<p><br></p>" ? (
                    <Row>
                        <Col className="mt-4 text-justify">
                            <div className='text-justify' dangerouslySetInnerHTML={{__html: Documento.CONTENIDO}}></div>
                        </Col>
                    </Row>
                    ) : null}
                    {Documento.PROSPECCION !== null && Documento.PROSPECCION !== "<p><br></p>" ? (
                    <Row className="resultBelt">
                        <Col className='mb-4 mt-4'>
                            <h3>Prospecci&oacute;n</h3>
                        </Col>
                    </Row>
                    ) : null}
                    {Documento.PROSPECCION !== null && Documento.PROSPECCION !== "<p><br></p>" ? (
                    <Row>
                        <Col className="mt-4 text-justify">
                            <div id="prospeccion"
                                className='text-justify'
                                onClick={handleProspection}
                            dangerouslySetInnerHTML={{__html: Documento.PROSPECCION}}>
                            </div>
                        </Col>
                    </Row>
                    ) : null}
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                        <Modal.Title>{tituloModal}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {contenidoModal}
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </Container>
            )}
        </>
    );
};

export { EvaluacionInteres };