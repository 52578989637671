// Este es un provider que obtiene el estado inicial de la aplicación desde el localStorage y
// lo guarda en el estado de la aplicación.

import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocalStorage } from '../Hooks/useLocalStorage';

const StateContext = createContext();

const StateProvider = ({ children }) => {
    const [state, setState] = useLocalStorage('state', {
        font_size: 14,
        view: "pais",
        country: null,
        productType: null,
        category: null,
        view_side_bar: false,
        view_search: false,
        keywords: "",
        pais_id: 0,
        categoria_id: 0,
        tipo_producto_id: 0,
        fecha_inicio: '',
        fecha_fin: '',
        producto_para_mostrar: null,
        resultado: [],
        destacado: true,
        token: "",
        usuario: null,
        navegacion: ["pais","categoria"],
        expiracion: "",
        consulting: false,
        makeRequest: false,
        loadLast5: true,
        last5: [],
        precargados: [],
        smShow: false,
        requeriminento: "",
        notify: false,
    });

    return (
        <StateContext.Provider value={{ state, setState }}>
            {children}
        </StateContext.Provider>
    );
}

export { StateProvider, StateContext };
