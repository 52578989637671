// react module Navbar
import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSearch, faClose, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import SideBar from '../sidebar/SideBar';
import Search from '../search/Search';
import { HelpModal } from '../Modal/HelpModal';

const NavBar = ({ state, setState }) => {
    const navigate = useNavigate();
    const [ infoShow, setInfoShow ] = React.useState(false);
    
    const handleSideBar = () => {        
        setState({
            ...state,
            view_side_bar: !state.view_side_bar,
            view_search: false,
        });
    };

    const handleLogo = () => {
        return navigate('/home');
    };

    const handleSearch = () => {
        setState({
            ...state,
            view_search: !state.view_search,
            view_side_bar: false,
        });

        if (state.view_search) {
            return navigate(-1);
        }
        return navigate('/buscar');
    };

    return (
        <>
            <Navbar bg="dark" expand="xs" variant="dark" fixed="top">
                <Container fluid>
                    <Nav>
                            <Button onClick={handleSideBar}>
                            {!state.view_side_bar 
                                ?(<FontAwesomeIcon icon={faBars} size="1x" color="white" />)
                                :(<FontAwesomeIcon icon={faClose} size="1x" color="white" />)}
                            </Button>
                    </Nav>
                    <Nav></Nav>
                    <Nav></Nav>
                    <Nav></Nav>
                    <Nav></Nav>
                    <Nav></Nav>
                    <Nav></Nav>
                    <Nav>
                        <img src="../logo.png" alt="logo" className="logo_ayp" onClick={handleLogo} />
                    </Nav>
                    <Nav></Nav>
                    <Nav></Nav>
                    <Nav></Nav>
                    <Nav></Nav>
                    <Nav></Nav>
                    <Nav>
                        <Button onClick={() => setInfoShow(true)}>
                            <FontAwesomeIcon icon={faQuestionCircle} size='1x'/>
                        </Button>
                    </Nav>
                    <Nav>
                        <Button onClick={handleSearch}>
                            {!state.view_search
                                ?(<FontAwesomeIcon icon={faSearch} size="1x" color="white" />)
                                :(<FontAwesomeIcon icon={faClose} size="1x" color="white" />)}
                        </Button>
                    </Nav>
                </Container>
            </Navbar>
            { state.view_side_bar ? (<SideBar state={state} setState={setState} />) : null }
            { state.view_search ? (<Search state={state} setState={setState} />) : null }
            <HelpModal infoShow={infoShow} setInfoShow={setInfoShow} state={state} setState={setState} />
        </>
    );
};

export { NavBar };