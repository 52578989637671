import React from 'react';
import ApiCalls from '../modules/ApiCalls';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SessionProvider = ({state, setState, documentos, setDocumentos}) => {

    const navigate = useNavigate();

    React.useEffect(() => {
        const api = new ApiCalls();
        let usuario_recuperado = null;
        let expiracion_recuperada = null;

        async function requestProducts() {
            let data = {
                TOKEN: state.token,
            };
            await axios({
                url: api.Request(),
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(data),
            }).then((response) => {
                if (response.data.status === "success") {
                    let resultado = response.data.data;
                    setDocumentos(resultado);
                    // Filtramos los 5 productos más recientes por FECHA y los asignamos en state.last5
                    let precargados = resultado.filter((producto) => {
                        return producto.FECHA !== null;
                    }).sort((a, b) => {
                        if(b.MES_EVALUACION !== null && a.MES_EVALUACION !== null){
                            return b.MES_EVALUACION - a.MES_EVALUACION;
                        }
                        if(b.MES_EVALUACION !== null && a.MES_EVALUACION === null){
                            return b.MES_EVALUACION - a.FECHA;
                        }
                        if(b.MES_EVALUACION === null && a.MES_EVALUACION !== null){
                            return b.FECHA - a.MES_EVALUACION;
                        }
                        return b.FECHA - a.FECHA;
                    }).slice(0, 50);
                    let last5 = precargados.slice(0, 5);

                    setState({
                        ...state,
                        view_search: false,
                        makeRequest: false,
                        last5: last5,
                        precargados: precargados,
                        loadLast5: false,
                        usuario: usuario_recuperado,
                        expiracion: expiracion_recuperada,
                    });
                    
                }
            }).catch((error) => {
                console.log(error);
            });
        }

        // Obtenenmos los datos de la sesión del usuario
        async function getAuth(access_token) {
            var data = { "TOKEN": access_token };
            await axios({
                url: api.Auth(),
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(data),
            }).then((response) => {
                if (response !== null && response.data.data !== null) {
                    response = response.data.data;
                    var sesiones = response.usuario.SESIONES;
                    var sesion = sesiones[sesiones.length - 1];
                    // Obtener fecha y hora actuales
                    var fecha_actual = new Date();
                    var fecha_expiracion = new Date(sesion.EXPIRACION);
                    // Comparar fechas
                    if (fecha_actual > fecha_expiracion) {
                        window.location.href = "https://ayplatam.com/Auth/Logout";
                    } else {
                        setState({
                            ...state,
                            usuario: response.usuario,
                            expiracion: sesion.EXPIRACION,
                            loadLast5: true
                        });
                        usuario_recuperado = response.usuario;
                        expiracion_recuperada = sesion.EXPIRACION;
                        requestProducts();
                        navigate('/home');
                    }
                }
            }).catch((error) => {
                // Subrutina rediriige a logout en ayplatam
                console.error('Error:', error);
            });
        }
        
        getAuth(state.token);
    }, [state.token]);

    React.useEffect(() => {
        let stringParams = window.location.hash.substring(3);
        let access_token = "";
        if(stringParams === null || stringParams === ''){
            stringParams = window.location.search;
            access_token = stringParams.split('access_token=')[1];
        } else {
            const searchParams = stringParams.split('access_token=');
            access_token = searchParams[1];
        }

        if (access_token) {
            setState({
                ...state,
                token: access_token
            })
        } else {
            if(state.expiracion !== null && state.expiracion !== ''){
                var fecha_actual = new Date();
                var fecha_expiracion = new Date(state.expiracion);
                if(fecha_expiracion < fecha_actual){
                    window.location.href = "https://ayplatam.com/Auth/Logout";
                } else {
                    if(documentos === null && documentos.length == 0){
                        setState({
                            ...state,
                            token: state.token
                        });
                    }
                    console.log("Logged In, Ok");
                }
            } else {
                window.location.href = "https://ayplatam.com/Auth/Logout";
            }
        }
    }, []);

}

export { SessionProvider };