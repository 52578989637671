import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Leyenda from "../../images/Leyenda.png";

const Kpi = ({ Valor }) => {
    const color = (Valor) => {
        if(Valor < -3){
            return "#C00000";
        }
        if(Valor < 0 && Valor >= -3){
            return "#ED7D31";
        }
        if(Valor >= 0 && Valor <= 3){
            return "#EDCF31";
        }
        if(Valor > 3){
            return "#449331";
        }
    }

    const style={
        borderRadius: "50%",
        width: "100px",
        height: "100px",
        color: "#FFFFFF",
        backgroundColor: color(Valor),
        margin: "auto",
        paddingTop: "20px",
        paddingBottom: "20px",
        fontSize: "35px",
        textAlign: "center"
    }

    return(
        <Container>
            <Row className="justify-content-md-center">
                <Col className="mb-2">
                    <div style={style}>
                        {Valor.toFixed(1)}
                    </div>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col>
                    <img src={Leyenda} alt="Leyenda" className="img-fluid" />    
                </Col>
            </Row>
        </Container>
    );
};

export default Kpi;