import React from 'react';
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import ApiCalls from '../ApiCalls';
import axios from 'axios';

const Requerimiento = ({ state, setState }) => {
    
    const handleClose = () => setState({...state, smShow: false});
    const [enableButton, setEnableButton] = React.useState(true);

    const handleChange = (e) => {
        setState({
            ...state,
            requerimiento: e.target.value,
        })
        if(state.requerimiento.length > 10){
            setEnableButton(false);
        } else {
            setEnableButton(true);
        }
    }

    async function sendRequeriment() {
        const api = new ApiCalls();
        const url = api.Notify();
        const data = {
            "TOKEN": state.token,
            "KEYWORDS": state.requerimiento,
        };
        await axios.post(url, data)
            .then((response) => {
                console.log(response);
                setState({
                    ...state,
                    requerimiento: '',
                    smShow: false,
                    notify: true,
                });
            }
            ).catch((error) => {
                console.log(error);
            }
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        sendRequeriment();
    }

    return (
        <>
            <Modal show={state.smShow} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>¿En qu&eacute; podemos apoyarte?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Row>
                            <Col>
                                <p>
                                Déjanos saber sobre que tema quisieras conocer más,
                                nuestros analistas atenderán tu requerimiento y pronto
                                lo encontrarás disponible en tu biblioteca.
                                </p>
                                <Form.Control as="textarea" 
                                    onChange={(e) => handleChange(e)}
                                rows={12} />
                            </Col>
                        </Row>
                    </Container>    
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        variant="primary"
                        onClick={handleSubmit}
                        disabled={enableButton}
                        >
                        Enviar requerimiento
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export { Requerimiento };