import React from 'react';
import { Container, Row, Col, Card, CardImg } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { CargandoCards } from '../Cargando/Cargando';
import boletines from '../../images/boletines.png';
import contexto from '../../images/contexto.png';
import informes from '../../images/informes.png';
import notasInteres from '../../images/notasInteres.png';
import evaluaciones from '../../images/evaluaciones.png';

const PerProductType = ({ state, setState, documentos }) => {
    const navigate = useNavigate();

    const productHandler = (productType) => {
        setState({
            ...state,
            view: productType.nombre,
            productType: productType,
            tipo_producto_id: productType.tipo_producto_id,
            consulting: true,
            makeRequest: true,
        });
        if(productType.tipo_producto_id == 4){
            return navigate('/evaluaciones');
        }
        return navigate('/explorar');
    };

    return(
        <>
        {documentos.length > 0?(  
            <Container fluid >
                <Row>
                    <Col className='mb-1 mt-3' xs={6} lg={2}>
                        <Card onClick={() => productHandler({nombre: "Evaluaciones de Intereses", tipo_producto_id: 4})}>
                            <CardImg src={evaluaciones} alt="Evaluaciones de intereses" />
                            <Card.Body className="text-center">
                                Evaluaciones de Interés
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='mb-1 mt-3' xs={6} lg={2}>
                        <Card onClick={() => productHandler({nombre: "Boletines Semanales", tipo_producto_id: 5})}>
                            <CardImg src={boletines} alt="Boletines" />
                            <Card.Body className="text-center">
                                Boletines Semanales
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='mb-1 mt-3' xs={6} lg={2}>
                        <Card onClick={() => productHandler({nombre: "Notas informativas", tipo_producto_id: 6})}>
                            <CardImg src={notasInteres} alt="Nota informativa" />
                            <Card.Body className="text-center">
                                Notas informativas
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='mb-1 mt-3' xs={6} lg={2}>
                        <Card onClick={() => productHandler({nombre: "Notas de Contexto", tipo_producto_id: 10})}>
                            <CardImg src={contexto} alt="Contexto" />
                            <Card.Body className="text-center">
                                Notas de Contexto
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='mb-1 mt-3' xs={6} lg={2}>
                        <Card onClick={() => productHandler({nombre: "Informes Estratégicos", tipo_producto_id: 7})}>
                            <CardImg src={informes} alt="Informe Estratégico" />
                            <Card.Body className="text-center">
                                Informes Estrat&eacute;gicos
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        ):(
            <CargandoCards/>
        )}
        </>
    );
}

export { PerProductType };