import React from 'react';
import { Row, Col, ListGroup } from 'react-bootstrap';
import Moment from 'moment';
import { useNavigate } from 'react-router-dom';

const DocumentList = ({ state, setState, documentos }) => {
    const navigate = useNavigate();

    if(documentos != null){
        // ordenar documentos por fecha de creación
        documentos = documentos.sort((a, b) => {
            return new Date(b.FECHA) - new Date(a.FECHA);
        });
    }

    const handleDocumentClick = (documento) => {
        setState({
            ...state,
            producto_para_mostrar: documento,
            consulting: true,
        });
        return navigate("/documento");
    };

    const handleContenido = (Contenido) => {
        if (Contenido != null && Contenido.length > 0){
            // remover tabulaciones
            Contenido = Contenido.replace(/\t/g, '');
            // remover saltos de línea
            Contenido = Contenido.replace(/\n/g, '');
            // remover espacios dobles
            Contenido = Contenido.replace(/\s\s/g, ' ');
            // remover espacios al inicio
            Contenido = Contenido.replace(/^\s/g, '');
            // remover espacios al final
            Contenido = Contenido.replace(/\s$/g, '');
            // remover etiquetas de html
            Contenido = Contenido.replace(/<[^>]*>?/gm, '');
            // remover espacios de html
            Contenido = Contenido.replace(/&nbsp;/g, '');
            if(Contenido.length > 350) {
                return Contenido.substring(0, 350) + '...';
            } else {
                return Contenido;
            }
        } else {
            return 'No existe vista previa';
        }
    };

    const handlePreview = (documento) => {
        if(documento.TIPO_PRODUCTO_ID == 4){
            return (
                <>
                    {handleContenido(documento.PROSPECCION)}
                </>
            );
        }
        if(documento.TIPO_PRODUCTO_ID == 5 && documento.URL != null){
            return (
                <>
                    {handleContenido(documento.CONTENIDO)}
                </>
            );
        }
        if(documento.TIPO_PRODUCTO_ID == 5 && documento.URL == null){
            return (
                <>
                    {handleContenido(documento.RESUMEN)}
                </>
            );
        }
        return (
            <>
                {handleContenido(documento.CONTENIDO)}
            </>
        );
    };

    const handleTitulo = (titulo) => {
        return(
            <>
                <div dangerouslySetInnerHTML={{__html: titulo}}></div>
            </>
        );
    }

    const handleFecha = (documento) => {
        if(documento.MES_EVALUACION != null){
            return Moment(documento.MES_EVALUACION).format('DD/MM/YYYY');
        }
        return Moment(documento.FECHA).format('DD/MM/YYYY');
    };

    return(
        <>
            {documentos.length > 0 ? (
                <ListGroup className="mt-4" style={{fontSize: state.font_size}}>
                    {documentos.map((item) => {
                        return(<ListGroup.Item
                            action
                            onClick={() => handleDocumentClick(item)}
                            key={"mv_l5_" + item.ID + "_" + new Date().getTime() + "_" + Math.random()}
                            className='col-lg-6 col-sm-12 col-xs-12 col-md-6'>
                                <Row>
                                    <Col xs={6}>
                                        {item.TIPO_PRODUCTO.NOMBRE}
                                    </Col>
                                    <Col xs={6} className="text-end">
                                        {handleFecha(item)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <h4>{handleTitulo(item.NOMBRE)}</h4>
                                        <p>
                                            {handlePreview(item)}
                                        </p>
                                    </Col>
                                </Row>
                            </ListGroup.Item>);
                        }
                    )}
                </ListGroup> 
            ) : (
                <p>No hay documentos para mostrar</p>
            )}
        </>
    );
};

export {DocumentList};