import { Container, Card, Placeholder, ListGroup, Row, Col, Spinner } from 'react-bootstrap';
import loading_map from '../../images/loading_map.gif';

const Cargando = ({state}) => {
    const items = [1, 2, 3, 4, 5];


    return(
        <>
            {state.loadLast5?(
                <ListGroup>
                    <Row>
                        <Col className='mb-4'>
                            <Spinner animation="border" variant="light" size="sm" /> Descargando información
                        </Col>
                    </Row>
                    {
                        items.map((item, index) => (
                            <ListGroup.Item key={"loading_object_"+index}>
                                <Row>
                                    <Col xs={6}>
                                        <Placeholder as="p" animation="wave">
                                            <Placeholder xs={6}/>
                                        </Placeholder>
                                    </Col>
                                    <Col xs={6} className="text-end">
                                        <Placeholder as="p" animation="wave">
                                            <Placeholder xs={6}/>
                                        </Placeholder>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Placeholder as="p" animation="wave">
                                            <Placeholder xs={2}/>
                                            <Placeholder xs={11}/>
                                            <Placeholder xs={8}/>
                                            <Placeholder xs={6}/>
                                            <Placeholder xs={8}/>
                                        </Placeholder>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        ))
                    }
                </ListGroup>)
            :(
                <Card>
                    <Card.Body>
                        <Card.Title>No hay ning&uacute;n producto para mostrar</Card.Title>
                    </Card.Body>
                </Card>
            )}
        </>
    );
};

const CargandoCards = () => {
    const items = [1, 2, 3, 4, 5, 6];
    return(
        <Container fluid>
            <Row>
                {
                    items.map((item, index) => (
                        <Col key={"loading_object_card_"+index} xs={4} lg={2} className="mt-3 mb-3">
                            <Card>
                                <Card.Img variant="top" src={loading_map}/>
                                <Card.Body>
                                    <Placeholder as={Card.Text} animation="glow">
                                        <Placeholder xs={12} />
                                    </Placeholder>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                }
            </Row>
        </Container>
    );
}

export {Cargando, CargandoCards};