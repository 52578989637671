import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Moment from 'moment';
import facebook from '../../images/facebook.png';
import instagram from '../../images/instagram.png';
import twitter from '../../images/twitter.png';
import linkedin from '../../images/linkedin.png';
import otro from '../../images/otro.png';

const Semblanza = ({ Documento, state }) => {
    const imagen = Documento.URL.replace(' ', '%20');
    const javascript_redes_sociales = JSON.parse(Documento.JAVASCRIPT);
    
    React.useEffect(() => {
        // Reiniciamos el scroll en cuanto se carga la vista correspondiente al producto
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    const redes_sociales = () => {
        return(
            <>
                {javascript_redes_sociales.facebook !== undefined 
                && javascript_redes_sociales.facebook !== null 
                && javascript_redes_sociales.facebook !== ''?(
                <a href={javascript_redes_sociales.facebook} target="_blank" rel="noopener noreferrer">
                    <img src={facebook} alt="facebook" style={{ maxHeight: '50px', maxWidth: '50px' }} />
                </a>):null}

                {javascript_redes_sociales.instagram !== undefined 
                && javascript_redes_sociales.instagram !== null 
                && javascript_redes_sociales.instagram !== ''?(
                <a href={javascript_redes_sociales.instagram} target="_blank" rel="noopener noreferrer">
                    <img src={instagram} alt="instagram" style={{ maxHeight: '50px', maxWidth: '50px' }} />
                </a>):null}

                {javascript_redes_sociales.twitter !== undefined 
                && javascript_redes_sociales.twitter !== null 
                && javascript_redes_sociales.twitter !== ''?(
                <a href={javascript_redes_sociales.twitter} target="_blank" rel="noopener noreferrer">
                    <img src={twitter} alt="twitter" style={{ maxHeight: '50px', maxWidth: '50px' }} />
                </a>):null}

                {javascript_redes_sociales.linkedin !== undefined 
                && javascript_redes_sociales.linkedin !== null 
                && javascript_redes_sociales.linkedin !== ''?(
                <a href={javascript_redes_sociales.linkedin} target="_blank" rel="noopener noreferrer">
                    <img src={linkedin} alt="linkedin" style={{ maxHeight: '50px', maxWidth: '50px' }} />
                </a>):null}

                {javascript_redes_sociales.otro !== undefined 
                && javascript_redes_sociales.otro !== null 
                && javascript_redes_sociales.otro !== ''?(
                <a href={javascript_redes_sociales.otro} target="_blank" rel="noopener noreferrer">
                    <img src={otro} alt="otro" style={{ maxHeight: '50px', maxWidth: '509px' }} />
                </a>):null}
            </>
        );
    }
    return(
        <Container fluid style={{fontSize: state.font_size}}>
            <Row className='justify-content-center'>
                <Col lg={2} xs={8} sm={6} className="mt-3">
                    <img src={imagen} alt={Documento.TITULO} className='img-fluid'/>
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col className="mt-3 mb-3 text-center">
                    {redes_sociales()}
                </Col>
            </Row>
            <Row className="resultBelt">
                <Col className="mt-3 mb-3">
                    <h3>Agenda</h3>
                </Col>
            </Row>
            <Row>
                <Col className="mt-3 mb-3">
                    <h5>{Documento.PAIS.NOMBRE}</h5>
                    <ul>
                        {Documento.RELACIONES_DOCUMENTO_INTERES.map((relacion, index) => {
                            return(
                                <li key={'s_rl_ci_' + index}>
                                    {relacion.CATEGORIA != null ? relacion.CATEGORIA.NOMBRE : ''}
                                    {relacion.INTERES != null ? '- ' + relacion.INTERES.NOMBRE : ''}
                                </li>
                            );
                        })}
                    </ul>
                </Col>
            </Row>
            <Row className="resultBelt">
                <Col className="mt-3 mb-3">
                    <h3>Semblanza</h3>
                </Col>
            </Row>
            <Row>
                <Col className="mt-3 mb-4">
                    <div dangerouslySetInnerHTML={{__html: Documento.CONTENIDO}}></div>
                </Col>
            </Row>
        </Container>
    );
}

export { Semblanza };