import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Boletin } from './Boletin';
import { EvaluacionInteres } from './EvaluacionInteres';
import { Semblanza } from './Semblanza';

const Producto = ({ state, setState }) => {

    const navigate = useNavigate();

    React.useEffect(() => {
        // Reiniciamos el scroll en cuanto se carga la vista correspondiente al producto
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    const backToResult = () => {
        setState({
            ...state,
            producto_para_mostrar: null,
        });
        return navigate(-1);
    };
    
    const handleDocumento = () => {
        let DOCUMENTO = state.producto_para_mostrar;
        if(DOCUMENTO === null){
            return (<></>);
        }
        if(DOCUMENTO.URL !== null && (DOCUMENTO.URL.includes('pdf') || DOCUMENTO.URL.includes('PDF'))){
            var url_path = DOCUMENTO.URL.replace(' ', '%20');
            return(
                <Container fluid>
                    <Row>
                        <Col>
                            <iframe src={`https://ayplatam.com/Scripts/ViewerJS/#${url_path}`} title={`${DOCUMENTO.NOMBRE}`} width="100%" height="600" allowFullScreen></iframe>
                        </Col>
                    </Row>
                </Container>
            );
        } else if(DOCUMENTO.TIPO_PRODUCTO.NOMBRE === 'Resumen Semanal') {
            return(
                <>
                    <Boletin Documento={DOCUMENTO} state={state}/>
                </>
            );
        } else if(DOCUMENTO.TIPO_PRODUCTO.NOMBRE === 'Evaluación de Intereses') {
            return(
                <>
                    <EvaluacionInteres Documento={DOCUMENTO} state={state}/>
                </>
            );
        } else if(DOCUMENTO.TIPO_PRODUCTO.NOMBRE === 'Semblanza'){
            return(
                <>
                    <Semblanza Documento={DOCUMENTO} state={state}/>
                </>
            );
        }   else {
            return(
                <>
                    <h1>Hubo un error cargando el archivo</h1>
                </>
            );
        }
    }

    const Titulo = () => {
        if(state.producto_para_mostrar !== null){
            let title = state.producto_para_mostrar.NOMBRE;
            // quitar html de title
            title = title.replace(/(<([^>]+)>)/gi, "");
            let fecha_mostrar = "";

            if(state.producto_para_mostrar.FECHA != null && state.producto_para_mostrar.MES_EVALUACION != null){
                if(state.producto_para_mostrar.FECHA !== state.producto_para_mostrar.MES_EVALUACION){
                    fecha_mostrar = Moment(state.producto_para_mostrar.FECHA).format('DD/MM/YYYY') + " - " + Moment(state.producto_para_mostrar.MES_EVALUACION).format('DD/MM/YYYY');
                } else {
                    fecha_mostrar = Moment(state.producto_para_mostrar.FECHA).format('DD/MM/YYYY');
                }
            } else {
                fecha_mostrar = Moment(state.producto_para_mostrar.FECHA).format('DD/MM/YYYY');
            }

            if(state.producto_para_mostrar.SEMANA != null && state.producto_para_mostrar.SEMANA !== '') {
                return (<>
                        {title} - <small>{state.producto_para_mostrar.SEMANA}</small>
                    </>);
            } else {
                return( <>
                    {title} - <small>{fecha_mostrar}</small> 
                </>);
            }
        } else {
            return (<></>);
        }
    
    }

    return (
        <>
            <Container fluid style={{fontSize: state.font_size}}>
                <Row className="resultBelt">
                    <Col className='mb-3 mt-3' xs={2} lg={1}>
                        <Button className="redondeado" variant='light' onClick={backToResult}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </Button>
                    </Col>
                    <Col className='mb-3 mt-3' xs={10} lg={6}>
                        <h3>
                            <Titulo/>
                        </h3>
                    </Col>
                </Row>
            </Container>
            {handleDocumento()}
        </>
    );
};

export { Producto };