import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import agenda  from '../../images/agenda.png'
import boletines from '../../images/boletines.png';
import contexto from '../../images/contexto.png';
import informes from '../../images/informes.png';
import notasInteres from '../../images/notasInteres.png';
import evaluaciones from '../../images/evaluaciones.png';

const Info = ({ state, setState }) => {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    const handleProductos = () => {
        if(state.usuario.NIVEL_AUTORIZACION_ID == 12 || state.usuario.NIVEL_AUTORIZACION_ID == 13){
            return(
                <>
                <Row className='resultBelt'>
                        <Col className='mb-1 mt-3 mb-3' xs={12} lg={12}>
                            <h2>Nuestros productos</h2>
                        </Col>
                    </Row>
                    <Row className='align-items-center'>
                        <Col className='mb-1 mt-3' xs={4} lg={1}>
                            <img src={evaluaciones} alt='evaluaciones' />
                        </Col>
                        <Col className='mb-1 mt-3' xs={8} lg={11}>
                            <h3>Evaluación periódica de intereses</h3>
                            <p>
                                Evaluación periódica de los intereses de acuerdo a los 
                                EEI's (Elemento Esencial de Información) definidios a
                                cada uno de estos y sus distintos escenarios definiendo
                                en este una posición de ventaja o desventaja,
                                así también como el análisis del impacto al negocio.
                            </p>
                        </Col>
                    </Row>
                    <Row className='align-items-center'>
                        <Col className='mb-1 mt-3' xs={4} lg={1}>
                            <img src={boletines} alt='boletines' />
                        </Col>
                        <Col className='mb-1 mt-3' xs={8} lg={11}>
                            <h3>Boletines semanales</h3>
                            <p>
                                Reporte semanal que incluye la situación actual
                                y análisis e impacto de lo ocurrido en el país.
                            </p>
                        </Col>
                    </Row>
                    <Row className='align-items-center'>
                        <Col className='mb-1 mt-3' xs={4} lg={1}>
                            <img src={notasInteres} alt='informes' />
                        </Col>
                        <Col className='mb-1 mt-3' xs={8} lg={11}>
                            <h3>Notas informativas</h3>
                            <p>
                                Documento de un tema en específico con el objetivode informar al cliente con un breve
                                análisis sobre su impacto sobre la Agenda de Intereses.
                            </p>
                        </Col>
                    </Row>
                    <Row className='align-items-center'>
                        <Col className='mb-1 mt-3' xs={4} lg={1}>
                            <img src={informes} alt='informes' />
                        </Col>
                        <Col className='mb-1 mt-3' xs={8} lg={11}>
                            <h3>Informes estratégicos</h3>
                            <p>
                                Documento acerca de un tema en específico que permite contextualizar al
                                cliente acerca de un evento, en dónde el análisis incluye prospectiva
                                con escenarios de lo que podría ocurrir y su impacto en la Agenda de Intereses.
                            </p>
                        </Col>
                    </Row>
                    <Row className='align-items-center'>
                        <Col className='mb-1 mt-3' xs={4} lg={1}>
                            <img src={contexto} alt='contexto' />
                        </Col>
                        <Col className='mb-1 mt-3' xs={8} lg={11}>
                        <h3>Notas de contexto</h3>
                            <p>
                                Documento que procesa la información general tras la
                                investigación de una región, país o ubicación de interés.
                            </p>
                        </Col>
                    </Row>
                </>
            );
        }
    };

    return (
        <>
            <Container fluid style={{ fontSize: state.fontSize }}>
                <Row>
                    <Col className='mb-1 mt-3 mb-3' xs={2} lg={1}>
                        <Button className="redondeado" variant='light' onClick={handleBack}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </Button>
                    </Col>
                    <Col className='mb-1 mt-3 mb-3' xs={10} lg={11}>
                        <h3>Información</h3>
                    </Col>
                </Row>
                <Row className='resultBelt'>
                    <Col className='mb-1 mt-3 mb-3' xs={12} lg={12}>
                        <h2>¿Qué es la Agenda de Intereses?</h2>
                    </Col>
                </Row>
                <Row className='align-items-center'>
                    <Col className='mb-1 mt-3' xs={4} lg={1}>
                        <img src={agenda} alt='agenda' />
                    </Col>
                    <Col className='mb-1 mt-3' xs={8} lg={11}>
                        Herramienta de análisis para la anticipación de oportunidades o
                        posibles amenazas de los puntos o temáticas de interés de los
                        tomadores de decisión (drivers habilitadores de lineamientos
                        estratégicos). 
                    </Col>
                </Row>
                <Row className='resultBelt'>
                    <Col className='mb-1 mt-3 mb-3' xs={12} lg={12}>
                        <h3>Categorías de la Agenda de Intereses</h3>
                    </Col>
                </Row>
                <Row>
                    <Col className='mb-1 mt-3' xs={12} lg={12}>
                        <h3>Economía</h3>
                        <p>
                            Refiere al registro y análisis de los indicadores de mayor
                            relevancia que reflejan la situación económica de un país.
                        </p>
                        <h3>Licencia Social</h3>
                        <p>
                            Refiere a las acciones por parte de “Progreso”, a lo interno y externo,
                            (que pudiese impactar en la dinámica de las comunidades en las distintas
                            áreas de influencia, autoridades y demás stakeholders) destinadas a
                            mantener la sostenibilidad de las operaciones.
                        </p>
                        <h3>Geopolítica</h3>
                        <p>
                            Refiere al estudio de los diversos sucesos políticos y económicos
                            acaecidos a nivel internacional, con el objetivo de identificar
                            los efectos que estos hechos generan a nivel general en la sociedad y,
                            en específico, en las operaciones de la empresa.
                        </p>
                        <h3>Cadena de valor</h3>
                        <p>
                            Refiere a los aspectos estratégicos e indispensables de la cadena
                            logística que permiten llevar a cabo el proceso de venta del
                            cemento en su totalidad. Desde la búsqueda de materias primas
                            e insumos, su transformación, fabricación, transporte hasta
                            la entrega al cliente final.
                        </p>
                        <h3>Comercial</h3>
                        <p>
                            Refiere a la dinámica del mercado y sus tendencias, acontecimientos
                            y cambios normativos que podrían influir en los precios, el
                            comportamiento de los productores y los consumidores. Busca
                            responder dos preguntas clave: ¿qué pasará con el mercado de
                            cemento en el país de interés? Y ¿qué pasará en el mercado de
                            cemento centroamericano?
                        </p>
                        <h3>Político / Legal</h3>
                        <p>
                            Refiere a todas las acciones, decisiones, aprobaciones y
                            ejecuciones -internas- relacionadas a los tres poderes del Estado
                            (Ejecutivo, Legislativo y Judicial), así como a los actores de
                            influencia, en el posicionamiento, cambio de la agenda de gobierno
                            o aprobación de nuevas normativas que pudieran desencadenar crisis
                            coyunturales (corrupción, gobernabilidad, legitimidad, paros,
                            manifestaciones) que afecten al sector privado, negocios de
                            interés o a las operaciones de la empresa.
                        </p>
                    </Col>
                </Row>
                {handleProductos()}
                
            </Container>
        </>
    );
};

export { Info };