import React from 'react';
import { InfoModal } from '../Modal/InfoModal';
import { Navbar, Nav, Container, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEnvelope, faUpLong } from '@fortawesome/free-solid-svg-icons';
import { Requerimiento } from '../Requerimiento/Requerimiento';

const FooterBar = ({ state, setState }) => {
    const [cargaShow, setCargaShow] = React.useState(false);

    const handleFontSize = (size) => {
        setState({
            ...state,
            font_size: size,
        });
    }

    const handleScroll = () => {
        window.scrollTo(0, 0);
    }

    return (
        <>
            <Navbar bg="dark" expand="xs" variant="dark" fixed="bottom">
                <Container fluid>
                    <Nav>
                        <Button onClick={() => handleFontSize('small')} style={{ fontSize: "12px"}}>
                            Aa
                        </Button>
                    </Nav>
                    <Nav>
                        <Button onClick={() => handleFontSize('medium')} style={{ fontSize: "14px"}}>
                            Aa
                        </Button>
                    </Nav>
                    <Nav>
                        <Button onClick={() => handleFontSize('large')} style={{ fontSize: "18px"}}>
                            Aa
                        </Button>
                    </Nav>
                    <Nav></Nav>
                    <Nav></Nav>
                    <Nav></Nav>
                    <Nav></Nav>
                    <Nav></Nav>
                    <Nav></Nav>
                    <Nav></Nav>
                    <Nav></Nav>
                    <Nav></Nav>
                    <Nav></Nav>
                    <Nav></Nav>
                    <Nav></Nav>
                    <Nav>
                        <Button onClick={()=>setState({...state, smShow: true })}>
                            <FontAwesomeIcon icon={faEnvelope} size="1x" color="white" />
                        </Button>
                    </Nav>
                    <Nav>
                        <Button
                            onClick={()=>setCargaShow(true)}>
                            {state.loadLast5?
                            (<Spinner animation="border" variant="light" size="sm" />):
                            (<FontAwesomeIcon icon={faCheck} size='1x'/>)}
                        </Button>
                    </Nav>
                    <Nav>
                        <Button onClick={handleScroll}>
                            <FontAwesomeIcon icon={faUpLong} size="1x" color="white" />
                        </Button>
                    </Nav>
                </Container>
            </Navbar>
            <InfoModal cargaShow={cargaShow} setCargaShow={setCargaShow} loadLast5={state.loadLast5} />
            {
                state.smShow?
                (<Requerimiento
                state={state}
                setState={setState}
                />
                ):null
            }
        </>
    );
}

export { FooterBar };