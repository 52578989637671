import React from 'react';
import { Container, Row, Col, Card, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { DocumentList } from '../DocumentList/DocumentList.js';
import { Cargando } from '../Cargando/Cargando.js';
import KPI from '../KPI/Kpi.js';
import Categories from '../FilterCatValue.js';
import {getFilter} from '../Filter.js';

const PerCategory = ({ state, setState, documentos }) => {

    let productos = (documentos.length > 0?documentos:state.precargados);
    let last5Cat = productos.length > 0?getFilter(productos, {PAIS_ID: state.pais_id}).slice(0,5): state.last5;

    const navigate = useNavigate();

    const ResetState = () => {
        setState({
            ...state,
            view: "",
        });
        return navigate(-1);
    };

    const categoryHandler = (category) => {
        setState({
            ...state,
            category: category,
            categoria_id: category.ID,
            view: state.country.nombre + " - " + category.NOMBRE,
            consuting: true,
            makeRequest: true,
        });
        navigate('/explorar');
    };

    const handleCategories = () => {
        if(productos.length > 0){
            const Categorias = Categories(state.pais_id, productos);
            const elemento = Categorias.map((category, index) => {
                return (
                    <Col className='mb-1 mt-3' xs={6} lg={2} key={'cd_ct_'+index} style={{fontSize: state.font_size}}>
                        <Card onClick={() => categoryHandler(category)}>
                            <Card.Body className="text-center">
                                <br/>
                                <KPI Valor={category.VALOR}/>
                                <br/>
                                <Card.Text>
                                    {category.NOMBRE}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                );
            });
            return elemento;
        } else {
            return (
                <Col className='mb-1 mt-3' xs={12} style={{fontSize: state.font_size}}>
                    <Card>
                        <Card.Body className="text-center">
                                <Spinner animation="border" variant="primary" size='sm' />&nbsp;&nbsp;
                                Descargando información...
                        </Card.Body>
                    </Card>
                </Col>
            );
        }
        return null;
    };

    const titleHandler = () => {
        if(state.country != null){
            return (<Row>
                <Col className='mb-1 mt-3' xs={2} lg={1}>
                    <Button className="redondeado" variant='light' onClick={ResetState}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </Button>
                </Col>
                <Col className='mb-1 mt-3' xs={10} lg={11}>
                    <h3>{state.country.nombre}</h3>
                </Col>
            </Row>);
        }
        return null;
    }

    return(
        <Container fluid >
            {titleHandler()}
            <Row>
                {handleCategories()}
            </Row>
            <Row>
                <Col className='mb-1 mt-3' xs={12}>
                    <h3>Lo más reciente</h3>
                    {
                        ((state.loadLast5===false || last5Cat.length > 0)
                            ?
                            <DocumentList
                                state={state}
                                setState={setState}
                                documentos={last5Cat}
                                style={{ fontSize: state.font_size }}
                            />
                            :
                            <Cargando state={state} />
                        )
                    }
                </Col>
            </Row>
        </Container>
    );
};

export { PerCategory };