import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Search = ({ state, setState }) => {
    const categorias = [
        { id: 18, nombre: 'Cadena de valor' },
        { id: 19, nombre: 'Comercial' },
        { id: 20, nombre: 'Económico' },
        { id: 21, nombre: 'Geopolítica' },
        { id: 22, nombre: 'Licencia Social' },
        { id: 23, nombre: 'Político / Legal' },
    ];

    const paises = [
        { id: 1, nombre: 'Guatemala' },
        { id: 2, nombre: 'Honduras' },
        { id: 3, nombre: 'Belice' },
        { id: 4, nombre: 'Panamá' },
        { id: 5, nombre: 'Costa Rica' },
        { id: 6, nombre: 'El Salvador' },
        { id: 7, nombre: 'Colombia' },
        { id: 25, nombre: 'Región' },
    ];

    const navigate = useNavigate();

    const handleKeywords = (e) => {
        setState({
            ...state,
            keywords: e.target.value,
        });
    };

    const handleCategoria = (e) => {
        setState({
            ...state,
            categoria_id: e.target.value,
        });
    };

    const handlePais = (e) => {
        setState({
            ...state,
            pais_id: e.target.value,
        });
    };

    const handleFechaInicio = (e) => {
        setState({
            ...state,
            fecha_inicio: e.target.value,
        });
    };

    const handleFechaFin = (e) => {
        setState({
            ...state,
            fecha_fin: e.target.value,
        });
    };

    const handleSubmit = () => {
        setState({
            ...state,
            view: 'Resultados',
            view_search: false,
        });
        navigate('/resultados');
    };

    return(
        <Container className="bg-sidebar sidebar" fluid>
            <Row>
                <Col className='mb-4 mt-3'>
                    <Form onSubmit={(e) => { e.preventDefault() }}>
                        <Form.Control
                            placeholder='Busca una frase'
                            aria-label='Busca una frase'
                            value={state.keywords}
                            onChange={handleKeywords}
                            type='search'
                            />
                        <br/>
                        Categor&iacute;a
                        <Form.Select aria-label='Categoría' onChange={handleCategoria} defaultValue={state.categoria_id}>
                            <option></option>
                            {categorias.map((categoria) => (
                                <option key={categoria.id} value={categoria.id}>
                                    {categoria.nombre}
                                </option>
                            ))}
                        </Form.Select>
                        <br/>
                        
                        Pa&iacute;s
                        <Form.Select aria-label='Categoría' onChange={handlePais} defaultValue={state.pais_id}>
                            <option></option>
                            {paises.map((pais) => (
                                <option key={pais.id} value={pais.id}>
                                    {pais.nombre}
                                </option>
                            ))}
                        </Form.Select>
                        <br/>
                        
                        Rango de fechas
                        <Form.Control
                            type='date'
                            placeholder='Fecha de inicio'
                            aria-label='Fecha de inicio'
                            value={state.fecha_inicio}
                            onChange={handleFechaInicio}
                            />
                        <br/>
                        <Form.Control
                            type='date'
                            placeholder='Fecha de fin'
                            aria-label='Fecha de fin'
                            min={state.fecha_inicio}
                            value={state.fecha_fin}
                            onChange={handleFechaFin}
                            />
                        <br/>
                        <Button type='button' onClick={handleSubmit} variant='primary'>Buscar</Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default Search;