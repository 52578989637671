import { map } from "fontawesome";

function getLastFive(productos){
    // Ordenar de mayor a menor el ID de los productos y devolver los primeros 5 de ese orden
    var last_five = productos.sort(function(a, b){
        return b.FECHA - a.FECHA;
    }
    ).slice(0, 5);
    return last_five;
}

function getCategory(productos, categoria_id){
    switch(categoria_id){
        case 18:
            return productos.filter(producto => 
                producto.RELACIONES_DOCUMENTO_INTERES.filter(relacion => 
                    relacion.CATEGORIA_ID == 18
                    || relacion.CATEGORIA_ID == 8
                    || relacion.CATEGORIA_ID == 4
                    ).length > 0);
        case 19:
            return productos.filter(producto =>
                producto.RELACIONES_DOCUMENTO_INTERES.filter(relacion =>
                    relacion.CATEGORIA_ID == 19
                    || relacion.CATEGORIA_ID == 6
                    || relacion.CATEGORIA_ID == 1
                    ).length > 0);
        case 20:
            return productos.filter(producto =>
                producto.RELACIONES_DOCUMENTO_INTERES.filter(relacion =>
                    relacion.CATEGORIA_ID == 20
                    || relacion.CATEGORIA_ID == 7
                    || relacion.CATEGORIA_ID == 2
                    ).length > 0);
        case 21:
            return productos.filter(producto =>
                producto.RELACIONES_DOCUMENTO_INTERES.filter(relacion =>
                    relacion.CATEGORIA_ID == 21
                    || relacion.CATEGORIA_ID == 10
                    || relacion.CATEGORIA_ID == 5
                    ).length > 0);
        case 22:
            return productos.filter(producto =>
                producto.RELACIONES_DOCUMENTO_INTERES.filter(relacion =>
                    relacion.CATEGORIA_ID == 22
                    || relacion.CATEGORIA_ID == 9
                    || relacion.CATEGORIA_ID == 5
                    ).length > 0);
        case 23:
            return productos.filter(producto =>
                producto.RELACIONES_DOCUMENTO_INTERES.filter(relacion =>
                    relacion.CATEGORIA_ID == 23
                    || relacion.CATEGORIA_ID == 11
                    || relacion.CATEGORIA_ID == 3
                    ).length > 0);
    }
}

function getFilter(productos, filter){
    let keywords = filter.KEYWORDS !== undefined?filter.KEYWORDS:'';
    let categoria_id = filter.CATEGORIA_ID !== undefined?(filter.CATEGORIA_ID !== ''?parseInt(filter.CATEGORIA_ID):0):0;
    let tipo_producto_id = filter.TIPO_PRODUCTO_ID !== undefined?(filter.TIPO_PRODUCTO_ID !== ''?parseInt(filter.TIPO_PRODUCTO_ID):0):0;
    let fecha_inicio = filter.FECHA_INICIO !== undefined?filter.FECHA_INICIO:'';
    let fecha_fin = filter.FECHA_FIN !== undefined?filter.FECHA_FIN:'';
    let pais_id = filter.PAIS_ID !== undefined?(filter.PAIS_ID !== ''?parseInt(filter.PAIS_ID):0):0;
    let resultado = [];

    if(keywords != null && keywords !== ""){
        let temp = productos;
        // limpiamos las keywords y normalizamos para que no importen los acentos, mayusculas, etc
        keywords = keywords.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        let temp2 = [];
        if(temp.length > 0){
            for(let i = 0; i < temp.length; i++){
                let producto = temp[i];
                let nombre = producto.NOMBRE?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                let contenido = producto.CONTENIDO?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                let resumen = producto.RESUMEN?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                let prospeccion = producto.PROSPECCION?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

                if(nombre?.includes(keywords) || contenido?.includes(keywords) || resumen?.includes(keywords) || prospeccion?.includes(keywords)){
                    // si el producto contiene las keywords, resaltamos las keywords en el contenido tomando el cuenta el index de la palabra y la longitud de la keyword
                    if(nombre?.includes(keywords)){
                        let index = nombre.indexOf(keywords);
                        let length = keywords.length;
                        producto.NOMBRE = producto.NOMBRE.substring(0, index) + "<span style='background: yellow !important; color: black !important;'>" + producto.NOMBRE.substring(index, index + length) + "</span>" + producto.NOMBRE.substring(index + length);
                    }
                    if(contenido?.includes(keywords)){
                        let index = contenido.indexOf(keywords);
                        let length = keywords.length;
                        producto.CONTENIDO = producto.CONTENIDO.substring(0, index) + "<span style='background: yellow !important; color: black !important;'>" + producto.CONTENIDO.substring(index, index + length) + "</span>" + producto.CONTENIDO.substring(index + length);
                    }
                    if(resumen?.includes(keywords)){
                        let index = resumen.indexOf(keywords);
                        let length = keywords.length;
                        producto.RESUMEN = producto.RESUMEN.substring(0, index) + "<span style='background: yellow !important; color: black !important;'>" + producto.RESUMEN.substring(index, index + length) + "</span>" + producto.RESUMEN.substring(index + length);
                    }
                    if(prospeccion?.includes(keywords)){
                        let index = prospeccion.indexOf(keywords);
                        let length = keywords.length;
                        producto.PROSPECCION = producto.PROSPECCION.substring(0, index) + "<span style='background: yellow !important; color: black !important;'>" + producto.PROSPECCION.substring(index, index + length) + "</span>" + producto.PROSPECCION.substring(index + length);
                    }
                    temp2.push(producto);
                }
            }
        }
        resultado = temp2;
    }

    if(categoria_id > 0 && tipo_producto_id > 0 && pais_id > 0){
        let temp = productos;
        let temp2 = [];
        if(resultado.length > 0){
            temp = resultado;
        }
        temp2 = temp.filter(producto => producto.TIPO_PRODUCTO_ID == tipo_producto_id && producto.PAIS_ID == pais_id);
        temp2 = getCategory(temp2, categoria_id);
        resultado = temp2;
    } 
    
    if(categoria_id > 0 && tipo_producto_id > 0 && pais_id === 0){
        let temp = productos;
        let temp2 = [];
        if(resultado.length > 0){
            temp = resultado;
        }
        temp2 = temp.filter(producto => producto.TIPO_PRODUCTO_ID == tipo_producto_id);
        temp2 = getCategory(temp2, categoria_id);
        resultado = temp2;
    } 
    
    if(categoria_id > 0 && tipo_producto_id === 0 && pais_id > 0){
        // filtrar productos PAIS_ID = pais_id
        let temp = productos;
        let temp2 = [];
        if(resultado.length > 0){
            temp = resultado;
        }
        temp2 = temp.filter(producto => producto.PAIS_ID == pais_id);
        temp2 = getCategory(temp2, categoria_id);
        resultado = temp2;
    }
    
    if(categoria_id === 0 && tipo_producto_id > 0 && pais_id > 0){
        let temp = productos;
        let temp2 = [];
        if(resultado.length > 0){
            temp = resultado;
        }
        temp2 = temp.filter(producto => producto.TIPO_PRODUCTO_ID == tipo_producto_id && producto.PAIS_ID == pais_id);
        resultado = temp2;
    } 
    
    if(categoria_id > 0 && tipo_producto_id === 0 && pais_id === 0){
        let temp = productos;
        let temp2 = [];
        if(resultado.length > 0){
            temp = resultado;
        }
        temp2 = getCategory(temp, categoria_id);
        resultado = temp2;
    } 
    
    if(categoria_id === 0 && tipo_producto_id > 0 && pais_id === 0){
        let temp = productos;
        let temp2 = [];
        if(resultado.length > 0){
            temp = resultado;
        }
        temp2 = temp.filter(producto => producto.TIPO_PRODUCTO_ID == tipo_producto_id);
        resultado = temp2;
    } 
    
    if(categoria_id === 0 && tipo_producto_id === 0 && pais_id > 0){
        let temp = productos;
        let temp2 = [];
        if(resultado.length > 0){
            temp = resultado;
        }   
        temp2 = temp.filter(producto => producto.PAIS_ID == pais_id);
        resultado = temp2;
    }
    
    if(fecha_inicio !== '' && fecha_fin !== ''){
        fecha_inicio = new Date(fecha_inicio);
        fecha_fin = new Date(fecha_fin);
        let temp = productos;
        let temp2 = [];
        if(resultado.length > 0){
            temp = resultado;
        }
        for(let i = 0; i < temp.length; i++){
            let producto = temp[i];
            let mes_evaluacion = new Date(producto.MES_EVALUACION);
            let fecha = new Date(producto.FECHA);
            if((fecha >= fecha_inicio && fecha <= fecha_fin)
                || (mes_evaluacion >= fecha_inicio && mes_evaluacion <= fecha_fin)){
                temp2.push(producto);
            }
        }
        resultado = temp2;
    }
    
    if(fecha_inicio !== '' && fecha_fin === ''){
        fecha_inicio = new Date(fecha_inicio);
        let temp = productos;
        let temp2 = [];
        if(resultado.length > 0){
            temp = resultado;
        }
        for(let i = 0; i < temp.length; i++){
            let producto = temp[i];
            let mes_evaluacion = new Date(producto.MES_EVALUACION);
            let fecha = new Date(producto.FECHA);
            if((fecha >= fecha_inicio) || (mes_evaluacion >= fecha_inicio)){
                temp2.push(producto);
            }
        }
        resultado = temp2;
    }

    if(fecha_inicio === '' && fecha_fin !== ''){
        fecha_fin = new Date(fecha_fin);
        let temp = productos;
        let temp2 = [];
        if(resultado.length > 0){
            temp = resultado;
        }
        for(let i = 0; i < temp.length; i++){
            let producto = temp[i];
            let mes_evaluacion = new Date(producto.MES_EVALUACION);
            let fecha = new Date(producto.FECHA);
            if((fecha <= fecha_fin) || (mes_evaluacion <= fecha_fin)){
                temp2.push(producto);
            }
        }
        resultado = temp2;
    }

    
    // ordenar resultados del más nuevo al más antiguo por su ID
    if(resultado.length > 0){
        resultado = resultado.sort(function(a, b){
            if(b.MES_EVALUACION !== null && a.MES_EVALUACION !== null){
                return b.MES_EVALUACION - a.MES_EVALUACION;
            }
            if(b.MES_EVALUACION !== null && a.MES_EVALUACION === null){
                return b.MES_EVALUACION - a.FECHA;
            }
            if(b.MES_EVALUACION === null && a.MES_EVALUACION !== null){
                return b.FECHA - a.MES_EVALUACION;
            }
            return b.FECHA - a.FECHA;
        });
    }

    return resultado;
}

export { getLastFive, getFilter };