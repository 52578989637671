import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { DocumentList } from "../DocumentList/DocumentList";
import { Cargando } from "../Cargando/Cargando";
import { DocumentHighLigth } from "../DocumentList/DocumentHighLigth";
import { useNavigate } from "react-router-dom";
import { getFilter } from "../Filter";

const RequestResult = ({ state, setState, documentos }) => {
    const navigate = useNavigate();

    let filtrados = [];

    if(state.productType !==null){
        filtrados = documentos.filter(producto => producto.TIPO_PRODUCTO_ID == state.productType.tipo_producto_id);
    }

    if(state.usuario !==null){
        let filtro = {};
        if(state.view == "Resultados"){
            filtro = {
                KEYWORDS: state.keywords,
                CATEGORIA_ID: state.categoria_id,
                TIPO_PRODUCTO_ID: state.tipo_producto_id,
                FECHA_INICIO: state.fecha_inicio,
                FECHA_FIN: state.fecha_fin,
                PAIS_ID: state.pais_id,
            };
        } else {
            filtro = {
                KEYWORDS: '',
                CATEGORIA_ID: state.categoria_id,
                TIPO_PRODUCTO_ID: state.tipo_producto_id,
                FECHA_INICIO: '',
                FECHA_FIN: '',
                PAIS_ID: state.pais_id,
            };
        }
        
        filtrados = getFilter(documentos, filtro);
    }

    const ResetState = () => {
        if(state.view == "Resultados"){
            setState({
                ...state,
                view: "",
                keywords: "",
                categoria_id: "",
                tipo_producto_id: "",
                fecha_inicio: "",
                fecha_fin: "",
                pais_id: "",
                view_search: false,
                view_sidebar: false,
            });
            return navigate("/");
        }
        setState({
            ...state,
            categoria_id: "",
            tipo_producto_id: "",
            fecha_inicio: "",
            fecha_fin: "",
            keywords: "",
        });
        return navigate(-1);
    };

    const takeLastEvaluation = () => {
        
        // Ordenamos los productos por FECHA
        if((state.productType != null && state.productType.tipo_producto_id == 4) || (state.usuario != null && state.usuario.NIVEL_AUTORIZACION_ID == 13)){
            let productos = filtrados.sort((a, b) => {
                return new Date(b.FECHA) - new Date(a.FECHA);
            });
            
            let evaluaciones = productos.filter(producto => producto.TIPO_PRODUCTO_ID == 4);
            let evaluacion = evaluaciones[0];
            
            return(
                <>
                {
                    state.view !== 'Resultados' && evaluaciones.length>0 ?
                    (
                        <Row>
                            <Col className="mt-4">
                                <DocumentHighLigth
                                    producto={evaluacion}
                                    state={state}
                                    setState={setState}/>
                            </Col>
                        </Row>
                    ):null
                }
                </>
            );
        }
    };

    return (

        <Container fluid>
            <Row>
                <Col className='mb-1 mt-3' xs={2} lg={1}>
                    <Button className="redondeado" variant='light' onClick={ResetState}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </Button>
                </Col>
                <Col className='mb-1 mt-3' xs={10} lg={11}>
                    <h3>{state.view}</h3>
                </Col>
            </Row>
            {(state.destacado?takeLastEvaluation():null)}
            <Row>
                <Col className="mt-1 mb-4">
                {
                    (filtrados.length>0 ? 
                        (<DocumentList
                            state={state}
                            setState={setState}
                            documentos={filtrados}
                        />)
                        :(<Cargando
                            state={state}
                        />)
                    )
                }
                </Col>
            </Row>
        </Container>
    );
};

export { RequestResult };