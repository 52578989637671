import React from 'react';
import { PerCountry } from '../../modules/per_country/PerCountry';
import { PerBoletines } from '../../modules/per_boletines/PerBoletines';
import { PerProductType } from '../../modules/per_product_type/PerProductType';
import { Container, Row, Col } from 'react-bootstrap';
import { DocumentList } from '../../modules/DocumentList/DocumentList';
import { Cargando } from '../../modules/Cargando/Cargando';

const Home = ({ state, setState, documentos, setDocumentos }) => {

    const ShowCategory = () => {
        if(state.usuario != null){
            const nivel = state.usuario.NIVEL_AUTORIZACION_ID;
            switch(nivel){
                case 11: {
                    return (
                        <PerBoletines
                            state={state}
                            setState={setState}
                            documentos={(documentos.length > 0?documentos:state.precargados)}
                            setDocumentos={setDocumentos}
                        />
                    );
                }
                case 12: {
                    return (
                        <PerProductType
                            state={state}
                            setState={setState}
                            documentos={(documentos.length>0?documentos:state.precargados)}
                            setDocumentos={setDocumentos}
                        />
                    );
                }
                case 13: {
                    return (
                        <PerCountry
                            state={state}
                            setState={setState}
                            documentos={(documentos.length > 0?documentos:state.precargados)}
                            setDocumentos={setDocumentos}
                        />
                    );
                }
            }
        }
        return (
            <></>
        );
    }

    return (
        <>
            <ShowCategory/>
            <Container fluid>
                <Row>
                    <Col className='mb-1 mt-3' xs={12}>
                        <h3>Lo más reciente</h3>
                        {
                            ((state.loadLast5===false || state.last5.length > 0)
                                ?
                                <DocumentList
                                    state={state}
                                    setState={setState}
                                    documentos={state.last5}
                                    style={{ fontSize: state.font_size }}
                                />
                                :
                                <Cargando state={state} />
                            )
                        }
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export { Home };