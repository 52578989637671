class ApiCalls {
  url = "https://ayplatam.com/api/prod";

  getBaseURL(){
    return this.url;
  }

  Auth() {
    var endpoint = this.url + "/auth";
    return endpoint;
  }

  LastFive() {
    return this.url + "/last5";
  }

  Request(){
    return this.url + "/consulta";
  }

  Notify(){
    return this.url + "/notify";
  }

  changePassword(){
    return this.url + "/changePassword";
  }
}

export default ApiCalls;