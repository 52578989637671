import React from 'react';
import { Container, Row, Col, Card, CardImg, ListGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import region_mapa from '../../images/mapa_region.png';
import guatemala_mapa from '../../images/guatemala.png';
import honduras_mapa from '../../images/honduras.png';
import el_salvador_mapa from '../../images/el_salvador.png';
import panama_mapa from '../../images/panama.png';
import colombia_mapa from '../../images/colombia.png';
import costa_rica_mapa from '../../images/costa_rica.png';
import belice_mapa from '../../images/belice.png';
import { CargandoCards } from '../Cargando/Cargando';
import { useNavigate } from 'react-router-dom';

const PerCountry = ({ state, setState, documentos }) => {
    const navigate = useNavigate();
    const height_images = '200px';
    const width_images = '200px';

    const ResetState = () => {
        setState({
            ...state,
            country: null,
            category: null,
            productType: null,
            pais_id: 0,
            categoria_id: 0,
            tipo_producto_id: 0,
            view: state.navegacion[0],
            resultado: [],
            consulting: false,
            makeRequest: false,
        });
    };

    const countryHandler = (country) => {
        if(state.navegacion[0] === 'pais'){
            setState({
                ...state,
                country: country,
                pais_id: country.pais_id,
                view: state.navegacion[1],
                consulting: true,
                makeRequest: true,
            });
        }
        if(state.navegacion[0] === 'categoria'){
            setState({
                ...state,
                country: country,
                pais_id: country.pais_id,
                view: state.category.NOMBRE + " - " + country.nombre,
                consulting: true,
                makeRequest: true,
            });
        }
        if(state.navegacion[0] === 'producto'){
            setState({
                ...state,
                country: country,
                pais_id: country.pais_id,
                view: state.productType.nombre + " - " + country.nombre,
                consulting: true,
                makeRequest: true,
            });
        }
        navigate('/pais/' + country.nombre);
    };

    return(
        <>
            {documentos.length > 0 ? (
                <Container fluid style={{fontSize: state.font_size}}>
                <Row>
                    <Col className='mb-1 mt-3' xs={12} lg={10}>
                        <Card onClick={() => countryHandler({nombre: "Región", pais_id: 25 })} className='text-center'>
                            <CardImg src={region_mapa} alt="Región" style={{ maxHeight: '200px', maxWidth: '350px', margin: 'auto'}} />
                            <Card.Body className="text-center">
                                Región
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='mb-1 mt-3' xs={4} lg={2}>
                        <Card onClick={() => countryHandler({nombre: "Guatemala", pais_id: 1 })} className='text-center'>
                            <CardImg src={guatemala_mapa} alt="Guatemala" style={{ maxHeight: height_images, maxWidth: width_images, margin: 'auto' }}/>
                            <Card.Body className="text-center">
                                Guatemala
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='mb-1 mt-3' xs={4} lg={2}>
                        <Card onClick={() => countryHandler({nombre: "Belice", pais_id: 3 })}>
                            <CardImg src={belice_mapa} alt="Belice" style={{ maxHeight: height_images, maxWidth: width_images, margin: 'auto' }}/>
                            <Card.Body className="text-center">
                                Belice
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='mb-1 mt-3' xs={4} lg={2}>
                        <Card onClick={() => countryHandler({nombre: "Honduras", pais_id: 2 })}>
                            <CardImg src={honduras_mapa} alt="Honduras" style={{ maxHeight: height_images, maxWidth: width_images, margin: 'auto' }}/>
                            <Card.Body className="text-center">
                                Honduras
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='mb-1 mt-3' xs={4} lg={2}>
                        <Card onClick={() => countryHandler({nombre: "El Salvador", pais_id: 6 })}>
                            <CardImg src={el_salvador_mapa} alt="El Salvador" style={{ maxHeight: height_images, maxWidth: width_images, margin: 'auto' }}/>
                            <Card.Body className="text-center">
                                El Salvador
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='mb-1 mt-3' xs={4} lg={2}>
                        <Card onClick={() => countryHandler({nombre: "Costa Rica", pais_id: 5 })}>
                            <CardImg src={costa_rica_mapa} alt="Costa Rica" style={{ maxHeight: height_images, maxWidth: width_images, margin: 'auto' }}/>
                            <Card.Body className="text-center">
                                Costa Rica
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='mb-1 mt-3' xs={4} lg={2}>
                        <Card onClick={() => countryHandler({nombre: "Panamá", pais_id: 4 })}>
                            <CardImg src={panama_mapa} alt="Panamá" style={{ maxHeight: height_images, maxWidth: width_images, margin: 'auto' }}/>
                            <Card.Body className="text-center">
                                Panam&aacute;
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='mb-1 mt-3' xs={4} lg={2}>
                        <Card onClick={() => countryHandler({nombre: "Colombia", pais_id: 7 })}>
                            <CardImg src={colombia_mapa} alt="Colombia" style={{ maxHeight: height_images, maxWidth: width_images, margin: 'auto' }}/>
                            <Card.Body className="text-center">
                                Colombia
                            </Card.Body>
                        </Card>
                    </Col>     
                </Row>
            </Container>
            ):
            <CargandoCards />}
        </>
    );
}

export { PerCountry };