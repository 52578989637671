import React from "react";
import { Container, Row, Col, Button, Card, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { DocumentList } from "../DocumentList/DocumentList";
import { Cargando } from "../Cargando/Cargando";
import { useNavigate } from "react-router-dom";
import { getFilter } from "../Filter";
import Categories from '../FilterCatValue.js';
import KPI from '../KPI/Kpi.js';

const Evaluaciones = ({ state, setState, documentos }) => {

    const navigate = useNavigate();

    let filtrados = [];

    if(state.productType !==null){
        filtrados = documentos.filter(producto => producto.TIPO_PRODUCTO_ID == state.productType.tipo_producto_id);
    }

    if(state.usuario !==null && state.usuario.NIVEL_AUTORIZACION_ID == 13){
        let filtro = {
            KEYWORDS: state.keywords,
            CATEGORIA_ID: state.categoria_id,
            TIPO_PRODUCTO_ID: state.tipo_producto_id,
            FECHA_INICIO: state.fecha_inicio,
            FECHA_FIN: state.fecha_fin,
            PAIS_ID: state.pais_id,
        };
        filtrados = getFilter(documentos, filtro);
    }

    const ResetState = () => {
        if(state.view == "Resultados"){
            return navigate("/");
        }
        setState({
            ...state,
            view: "",
        });
        return navigate(-1);
    };

    const categoryHandler = (category) => {
        setState({
            ...state,
            category: category,
            categoria_id: category.ID,
            view: category.NOMBRE,
            consuting: true,
            makeRequest: true,
        });
        return navigate('/explorar');
    };

    const handleCategories = () => {
        if(documentos.length > 0){
            const Categorias = Categories(state.usuario.PAIS_ID, documentos);
            const elemento = Categorias.map((category, index) => {
                return (
                    <Col className='mb-1 mt-3' xs={6} lg={2} key={'cd_ct_'+index} style={{fontSize: state.font_size}}>
                        <Card onClick={() => categoryHandler(category)}>
                            <Card.Body className="text-center">
                                <br/>
                                <KPI Valor={category.VALOR}/>
                                <br/>
                                <Card.Text>
                                    {category.NOMBRE}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                );
            });
            return elemento;
        } else {
            return (
                <Col className='mb-1 mt-3' xs={12} style={{fontSize: state.font_size}}>
                    <Card>
                        <Card.Body className="text-center">
                                <Spinner animation="border" variant="primary" size='sm' />&nbsp;&nbsp;
                                Descargando información...
                        </Card.Body>
                    </Card>
                </Col>
            );
        }
        return null;
    };


    return (

        <Container fluid>
            <Row>
                <Col className='mb-1 mt-3' xs={2} lg={1}>
                    <Button className="redondeado" variant='light' onClick={ResetState}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </Button>
                </Col>
                <Col className='mb-1 mt-3' xs={10} lg={11}>
                    <h3>Evaluaciones</h3>
                </Col>
            </Row>
            <Row>
                {handleCategories()}
            </Row>
            <Row>
                <Col className="mt-1 mb-4">
                {
                    (filtrados.length>0 ? 
                        (<DocumentList
                            state={state}
                            setState={setState}
                            documentos={filtrados}
                        />)
                        :(<Cargando
                            state={state}
                        />)
                    )
                }
                </Col>
            </Row>
        </Container>
    );
};

export { Evaluaciones };