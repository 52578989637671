// Este es el hook que vamos a utilizar para guardar los datos en el localStorage, estos datos son por ejemplo el estado.
// Este hook recibe como parámetro el nombre de la llave y el valor inicial.
// El hook retorna el valor y una función para actualizar el valor.

import { useState } from 'react';

const useLocalStorage = (key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.log(error);
            return initialValue;
        }
    });

    const setValue = value => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            window.localStorage
                .setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.log(error);
        }
    };

    return [storedValue, setValue];
}

export { useLocalStorage };