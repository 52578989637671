import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';
import { NavBar } from './modules/navbar/Navbar';
import { FooterBar } from './modules/footerbar/Footerbar';
import { Home } from './Pages/Home/Home';
import { Info } from './Pages/Info/Info';
import { StateProvider, StateContext } from './Providers/stateProvider';
import { SessionProvider } from './Providers/sessionProvider';
import { PerCategory } from './modules/per_category/PerCategory';
import { Boletines } from './modules/DocumentList/Boletines';
import { Producto } from './modules/DocumentList/Producto';
import { RequestResult } from './modules/RequestResult/RequestResult';
import { Evaluaciones } from './modules/DocumentList/Evaluaciones';
import ChangePassword from './modules/ChangePass/ChangePassword';
import Search from './modules/search/Search';

function App() {
    const [ documentos, setDocumentos ] = React.useState([]);

    return (
        <StateProvider>
            <StateContext.Consumer>
            {({ state, setState }) =>(
                <>
                    <HashRouter>
                        <SessionProvider state={state}
                            setState={setState}
                            documentos={documentos}
                            setDocumentos={setDocumentos} />
                        <NavBar state={state} setState={setState} />
                        <Routes>
                            <Route path="/" element={
                                <Home
                                    state={state}
                                    setState={setState}
                                    documentos={documentos} />
                            } />
                            <Route path="/home" element={
                                <Home
                                    state={state}
                                    setState={setState}
                                    documentos={documentos} />
                            } />
                            <Route path="/info" element={
                                <Info
                                    state={state}
                                    setState={setState} />
                            } />
                            <Route path="/pais/:pais_nombre" element={
                                <PerCategory
                                    state={state}
                                    setState={setState}
                                    documentos={documentos} />
                            } />
                            <Route path="/boletines" element={
                                <Boletines
                                    state={state}
                                    setState={setState}
                                    documentos={(documentos.length>0?documentos:state.precargados)} />
                            } />
                            <Route path="/evaluaciones" element={
                                <Evaluaciones
                                    state={state}
                                    setState={setState}
                                    documentos={(documentos.length>0?documentos:state.precargados)} />
                            } />
                            <Route path="/explorar" element={
                                <RequestResult
                                state={state}
                                setState={setState}
                                documentos={(documentos.length>0?documentos:state.precargados)} />
                            } />
                            <Route path="/resultados" element={
                                <RequestResult
                                state={state}
                                setState={setState}
                                documentos={(documentos.length>0?documentos:state.precargados)} />
                            } />
                            <Route path="/buscar" element={
                                <Search
                                    state={state}
                                    setState={setState} />
                            } />

                            <Route path="/documento" element={
                                <Producto
                                    state={state}
                                    setState={setState} />
                            } />
                            <Route path="/password" element={
                                <ChangePassword
                                    state={state}
                                    setState={setState} />
                            } />
                        </Routes>
                        <FooterBar state={state} setState={setState} />
                    </HashRouter>
                </>
                )}
            </StateContext.Consumer>
        </StateProvider>
    );
};

export default App;