import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

import Guatemala from '../../images/guatemala.png';
import ElSalvador from '../../images/el_salvador.png';
import Honduras from '../../images/honduras.png';
import Region from '../../images/mapa_region.png';
import CostaRica from '../../images/costa_rica.png';
import Colombia from '../../images/colombia.png';
import Panama from '../../images/panama.png';
import Belice from '../../images/belice.png';


const Boletin = ({ Documento, state }) => {
    
    React.useEffect(() => {
        // Reiniciamos el scroll en cuanto se carga la vista correspondiente al producto
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    const handleMap = (nombre) => {  
        switch(nombre) {
            case 'Guatemala':
                return Guatemala;
            case 'El Salvador':
                return ElSalvador;
            case 'Honduras':
                return Honduras;
            case 'Región':
                return Region;
            case 'Costa Rica':
                return CostaRica;
            case 'Colombia':
                return Colombia;
            case 'Panamá':
                return Panama;
            case 'Belice':
                return Belice;
        }
    };

    const handleBoletin = () => {
        if(Documento.TIPO_PRODUCTO.NOMBRE === 'Resumen Semanal' && Documento.URL !== null) {
            var url_path = Documento.URL.replace(' ', '%20');
            return(
                <>
                    <iframe src={`https://ayplatam.com/Scripts/ViewerJS/#${url_path}`} title={`${Documento.NOMBRE}`} width="100%" height="600" allowFullScreen></iframe>
                </>
            );
        } else {
            let relaciones_interes = Documento.RELACIONES_DOCUMENTO_INTERES;

            let contexto = Documento.RESUMEN;
            let impacto = Documento.CONTENIDO;

            return(
                <>
                    <Container fluid style={{fontSize: state.font_size}}>
                        <Row>
                            <Col className='mb-4 mt-4 text-center'>
                            {(Documento.PAIS != null?
                                (<img style={{ "height": "150px", "width": "auto" }} src={handleMap(Documento.PAIS.NOMBRE)}/>)
                                :'')}
                            </Col>
                        </Row>
                        <Row className="resultBelt">
                            <Col className='mb-4 mt-4 text-justify'>
                                <h3>Agenda de inter&eacute;s</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='mt-4 mb-1 text-justify'>
                                <ul>
                                    {relaciones_interes.map((relacion, index) => (
                                        <li key={index}>
                                            {(relacion.CATEGORIA != null?relacion.CATEGORIA.NOMBRE:'')}
                                            {(relacion.INTERES != null?' - ' + relacion.INTERES.NOMBRE:'')}
                                        </li>
                                    ))}
                                </ul>
                            </Col>
                        </Row>
                        <Row className="resultBelt">
                            <Col className='mb-4 mt-4 text-justify'>
                                <h3>Contexto</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='mt-4'>
                                <div className='text-justify' dangerouslySetInnerHTML={{__html: contexto}}></div>
                            </Col>
                        </Row>
                        <Row className="resultBelt">
                            <Col className='mb-4 mt-4 text-justify'>
                                <h3>Impacto en la Agenda de Intereses</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='mt-4'>
                                <div className='text-justify' dangerouslySetInnerHTML={{__html: impacto}}></div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-end">
                                <p className="text-danger">
                                    CONFIDENCIAL
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </>
            );
        }
    };
    
    return (
        <>
            {handleBoletin()}
        </>
    );
};

export { Boletin };