import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const HelpModal = ({ infoShow, setInfoShow, state, setState }) => {

    const [ texto, setTexto ] = React.useState('');
    const [ titulo, setTitulo ] = React.useState('');
    // get current url
    const currentUrl = window.location.href;

    const navigate = useNavigate();

    const handleInfo = () => {
        setInfoShow(false);
        return navigate('/info');
    }

    const handleEvaluaciones = () => {
        setTitulo('Evaluaciones de Interés');
        setTexto('<p>Evaluación periódica de los intereses de acuerdo a los EEI\'s (Elemento Esencial de Información) definidos a cada uno de  estos y sus distintos escenarios definiendo en este una posición de ventaja o desventaja, así también como el análisis del impacto al negocio.</p>');
    }

    const handleBoletines = () => {
        setTitulo('Boletines Semanales');
        setTexto('<p>Reporte semanal que incluye la situación actual y análisis de impacto de lo ocurrido en el país.</p>');
    }

    const handleNota = () => {
        setTitulo('Notas informativas');
        setTexto('<p>Documento de un tema en específico con el objetivo de informar al cliente con un breve análisis sobre su impacto sobre la Agenda de Intereses.</p>');
    }

    const handleContexto = () => {
        setTitulo('Notas de Contexto');
        setTexto('<p>Documento que procesa la información general tras la investigación de una región, país o ubicación de interés.</p>');
    }

    const handleInforme = () => {
        setTitulo('Informes Estratégicos');
        setTexto('<p>Documento acerca de un tema en específico que permite contextualizar al cliente acerca de un evento, en dónde el análisis incluye prospectiva con escenarios de lo que podría ocurrir y su impacto en la Agenda de Intereses.</p>');
    }

    React.useEffect(() => {
        const usuario = state.usuario;
        if(usuario !== null){
            if(usuario.NIVEL_AUTORIZACION_ID == 12){
                if(currentUrl.includes('home')){
                setTitulo('Bienvenido a AyPLatam');
                setTexto('En esta sección encontrarás accesos directos a la información de cada país en donde operamos.');
                }
            }
            if(usuario.NIVEL_AUTORIZACION_ID == 13){
                if(currentUrl.includes('home')){
                setTitulo('Bienvenido a AyPLatam');
                setTexto('En esta sección encontrarás accesos directos a la información de cada país relacionado con la organización.');
                }
                if(currentUrl.includes('pais')){
                    setTitulo(state.country.nombre);
                    setTexto('Podrás ver las categorías relacionadas con ' + state.country.nombre + ' y los documentos que se encuentran en cada una de ellas.');
                }
            }
            if(usuario.NIVEL_AUTORIZACION_ID == 11){
                if(currentUrl.includes('home')){
                    setTitulo('Boletín Semanal');
                    setTexto('<p>Reporte semanal que incluye la situación actual y análisis de impacto de lo ocurrido en el país.</p>');
                }
            }
        }

        if (currentUrl.includes('evaluaciones')){
            handleEvaluaciones();
        }

        if (currentUrl.includes('explorar')){
            const view = state.view;
            if(view == 'Boletines Semanales'){
                handleBoletines();
            }
            if(view == 'Notas informativas'){
                handleNota();
            }
            if(view == 'Notas de Contexto'){
                handleContexto();
            }
            if(view == 'Informes Estratégicos'){
                handleInforme();
            }
            if(state.category != null && view.includes(state.category.NOMBRE)){
                const categoria = state.category.NOMBRE;
                setTitulo(categoria);
                if(categoria == 'Comercial'){
                    setTexto('Refiere a la dinámica del mercado y sus tendencias, acontecimientos y cambios normativos que podrían influir en los precios, el comportamiento de los productores y los consumidores. Busca responder dos preguntas clave: ¿qué pasará con el mercado de cemento en el país de interés? Y ¿qué pasará en el mercado de cemento centroamericano?');
                }
                if(categoria == 'Geopolítica'){
                    setTexto('Refiere al estudio de los diversos sucesos políticos y económicos acaecidos a nivel internacional, con el objetivo de identificar los efectos que estos hechos generan a nivel general en la sociedad y, en específico, en las operaciones de la empresa.');
                }
                if(categoria == 'Cadena de Valor'){
                    setTexto('Refiere a los aspectos estratégicos e indispensables de la cadena logística que permiten llevar a cabo el proceso de venta del cemento en su totalidad. Desde la búsqueda de materias primas e insumos, su transformación, fabricación, transporte hasta la entrega al cliente final.');
                }
                if(categoria == 'Económico'){
                    setTexto('Refiere al registro y análisis de los indicadores de mayor relevancia que reflejan la situación económica de un país.');
                }
                if(categoria == 'Licencia Social'){
                    setTexto('Refiere a las acciones por parte de “Progreso”, a lo interno y externo, (que pudiese impactar en la dinámica de las comunidades en las distintas áreas de influencia, autoridades y demás stakeholders) destinadas a mantener la sostenibilidad de las operaciones.');
                }
                if(categoria == 'Político / Legal'){
                    setTexto('Refiere a todas las acciones, decisiones, aprobaciones y ejecuciones -internas- relacionadas a los tres poderes del Estado (Ejecutivo, Legislativo y Judicial), así como a los actores de influencia, en el posicionamiento, cambio de la agenda de gobierno o aprobación de nuevas normativas que pudieran desencadenar crisis coyunturales (corrupción, gobernabilidad, legitimidad, paros, manifestaciones) que afecten al sector privado, negocios de interés o a las operaciones de la empresa.');
                }
            }
        }

        if(currentUrl.includes('documento')){
            const producto = state.producto_para_mostrar;
            if(producto.TIPO_PRODUCTO_ID == 4){
                handleEvaluaciones();
            }
            if(producto.TIPO_PRODUCTO_ID == 5){
                handleBoletines();
            }
            if(producto.TIPO_PRODUCTO_ID == 6){
                handleNota();
            }
            if(producto.TIPO_PRODUCTO_ID == 7){
                handleInforme();
            }
            if(producto.TIPO_PRODUCTO_ID == 10){
                handleContexto();
            }
        }
    }, [currentUrl]);

    return(
        <Modal
        size="sm"
        show={infoShow}
        onHide={() => setInfoShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
        centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-sm">
                    {titulo}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div dangerouslySetInnerHTML={{__html: texto}}></div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleInfo}>
                    Saber m&aacute;s
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export { HelpModal }