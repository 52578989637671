import Modal from 'react-bootstrap/Modal';

const InfoModal = ({ cargaShow, setCargaShow, loadLast5 }) => {
    return(
        <Modal
        size="sm"
        show={cargaShow}
        onHide={() => setCargaShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
        centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-sm">
                    AyP
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loadLast5?
                (<>Se están descargando los documentos más recientes, esto puede tomar unos segundos.</>):
                (<>Tus documentos para consulta han sido actualizados con éxito, disfruta de navegar en ayplatam</>)}
            </Modal.Body>
        </Modal>
    );
}

export { InfoModal }