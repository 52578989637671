import { Container, Row, Col, Card, CardImg } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { CargandoCards } from '../Cargando/Cargando';
import boletines from '../../images/boletines.png';

const PerBoletines = ({ state, setState, documentos }) => {
    
    const navigate = useNavigate();

    const handleBoletines = () => {
        return navigate('/boletines');
    };

    return(
        <>
        {documentos.length > 0 ?(
            <Container fluid>
                <Row className="justify-content-center">
                    <Col sm={8} xs={8} className="mb-1 mt-3">
                        <Card onClick={() => handleBoletines() }>
                            <CardImg src={boletines} alt="Boletines" style={{ maxHeight: '200px', maxWidth: '200px', margin: 'auto' }} />
                            <Card.Body className="text-center">
                                Boletines
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>):
            (<CargandoCards/>)}
        </>
    );
}

export { PerBoletines }