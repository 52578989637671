import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, CardImg, ListGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { DocumentList } from './DocumentList.js';

const Boletines = ({ state, setState, documentos }) => {
    
    const navigate = useNavigate();

    const backHandler = () => {
        // back in history
        return navigate(-1);
    }

    return(
        <Container fluid>
            <Row>
                <Col className='mb-1 mt-3' xs={2} lg={1}>
                    <Button className="redondeado" variant='light' onClick={() => backHandler()}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </Button>
                </Col>
                <Col className='mb-1 mt-3' xs={10} lg={6}>
                    <h3>Boletines</h3>
                </Col>
            </Row>
            <DocumentList
                state={state}
                setState={setState}
                documentos={documentos} />
        </Container>
    );
};

export { Boletines };