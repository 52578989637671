import React from 'react';
import { Container, Col, Row, Form, Button, Card, Alert } from 'react-bootstrap';
import ApiCalls from '../ApiCalls';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const ChangePassword = ({ state, setState }) => {
    const navigate = useNavigate();
    const token = state.token;
    const [Password, setPassword] = React.useState('');
    const [RepeatPassword, setRepeatPassword] = React.useState('');
    const [Message, setMessage] = React.useState('');
    const [MessageType, setMessageType] = React.useState('');

    const handleBack = () => {
        navigate(-1);
    };

    async function handleAxios() {
        const api = new ApiCalls();
        var data = { "TOKEN": token, "KEYWORDS": Password };
        await axios ({
            url: api.changePassword(),
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(data),
        }).then((response) => {
            if (response !== null && response.data.data !== null) {
                if (response.data.data === 'OK') {
                    setMessage('Contraseña cambiada correctamente');
                    setMessageType('success');
                    setPassword('');
                    setRepeatPassword('');
                } else {
                    setMessage('No se pudo cambiar la contraseña');
                    setMessageType('danger');
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const handlePasswords = () => {
        // comprobar que las contraseñas coincidan y que cumplan con los requisitos: Tener al menos una letra mayúscula, un número y un caracter especial
        if (Password === RepeatPassword) {
            if (Password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])([A-Za-z\d$@$!%*?&]|[^ ]){8,}$/)) {
                handleAxios();
            } else {
                setMessage('La contraseña debe tener al menos una letra mayúscula, un número y un caracter especial');
                setMessageType('warning');
            }
        } else {
            setMessage('Las contraseñas no coinciden');
            setMessageType('warning');
        }
    }

    return (
        <Container>
            <Row>
                <Col className='mb-1 mt-3' xs={2} lg={1}>
                    <Button className="redondeado" variant='light' onClick={handleBack}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </Button>
                </Col>
                <Col className='mb-1 mt-3' xs={10} lg={11}>
                    <h3>Cambiar Contraseña</h3>
                </Col>
            </Row>
            <Row>
                <Col className="mt-4">
                    <Card>
                        <Card.Body>
                            <Form>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Nueva contraseña</Form.Label>
                                    <Form.Control type="password" value={Password} placeholder="Contraseña" onChange={(e) => setPassword(e.target.value)} />
                                </Form.Group>
                                <Form.Group controlId="formRepeatBasicPassword">
                                    <Form.Label>Repetir contraseña</Form.Label>
                                    <Form.Control type="password" value={RepeatPassword} placeholder="Contraseña" onChange={(e) => setRepeatPassword(e.target.value)} />
                                </Form.Group>
                                <br/>
                                <Form.Group>
                                    <Button variant="primary" onClick={handlePasswords}>
                                        Cambiar contraseña
                                    </Button>
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {Message !== '' &&(
            <Row>
                <Col>
                    <Alert variant={MessageType}>
                        {Message}
                    </Alert>
                </Col>
            </Row>)}
        </Container>
    );

};

export default ChangePassword;