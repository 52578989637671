let categorias = [
    { ID: 18, NOMBRE: "Cadena de Valor", VALOR: 0 },
    { ID: 19, NOMBRE: "Comercial", VALOR: 0 },
    { ID: 20, NOMBRE: "Económico", VALOR: 0 },
    { ID: 21, NOMBRE: "Geopolítica", VALOR: 0 },
    { ID: 22, NOMBRE: "Licencia Social", VALOR: 0 },
    { ID: 23, NOMBRE: "Político / Legal", VALOR: 0 },
];

function Guatemala(productos){
    let categorias = [
        { ID: 18, NOMBRE: "Cadena de Valor", VALOR:  0 },
        { ID: 20, NOMBRE: "Económico", VALOR:  0 },
        { ID: 22, NOMBRE: "Licencia Social", VALOR: 0 },
        { ID: 23, NOMBRE: "Político / Legal", VALOR: 0 },
    ];
    // Ordenamos los productos por FECHA
    productos = productos.sort((a, b) => {
        return new Date(b.FECHA) - new Date(a.FECHA);
    });

    let javascript_content = null;
    let kpi = 0;
    let kpi_average = 0;

    // Obtenemos las evaluaciones de intereses que correspondan a Guatemala para darle sentido a los valores de las categorias
    let productos_guatemala = productos.filter(producto => producto.TIPO_PRODUCTO_ID == 4 && producto.PAIS_ID == 1);

    let cadena_valor = productos_guatemala.filter(producto => 
        producto.RELACIONES_DOCUMENTO_INTERES.filter(
            relacion => relacion.CATEGORIA_ID == 18
            || relacion.CATEGORIA_ID == 8
            || relacion.CATEGORIA_ID == 2
            ).length > 0);
    if(cadena_valor[0] != null && cadena_valor[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(cadena_valor[0].JAVASCRIPT); 
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[0].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[0].VALOR = kpi;
        }
    }

    let productos_economicos = productos_guatemala.filter(producto =>
        producto.RELACIONES_DOCUMENTO_INTERES.filter(
            relacion => relacion.CATEGORIA_ID == 20
            || relacion.CATEGORIA_ID == 7
            || relacion.CATEGORIA_ID == 1
            ).length > 0);
    if(productos_economicos[0] != null && productos_economicos[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(productos_economicos[0].JAVASCRIPT);
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[1].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[1].VALOR = kpi;
        }
    }

    let productos_licencia = productos_guatemala.filter(producto =>
        producto.RELACIONES_DOCUMENTO_INTERES.filter(
            relacion => relacion.CATEGORIA_ID == 22
            || relacion.CATEGORIA_ID == 9
            || relacion.CATEGORIA_ID == 5
            ).length > 0);
    if(productos_licencia[0] != null && productos_licencia[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(productos_licencia[0].JAVASCRIPT);
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[2].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[2].VALOR = kpi;
        }
    }

    let productos_politico = productos_guatemala.filter(producto =>
        producto.RELACIONES_DOCUMENTO_INTERES.filter(
            relacion => relacion.CATEGORIA_ID == 23
            || relacion.CATEGORIA_ID == 11
            || relacion.CATEGORIA_ID == 5
            ).length > 0);
    if(productos_politico[0] != null && productos_politico[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(productos_politico[0].JAVASCRIPT);
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[3].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[3].VALOR = kpi;
        }
    }

    return categorias;
}

function Honduras(productos){
    let categorias = [
        { ID: 18, NOMBRE: "Cadena de Valor", VALOR:  0 },
        { ID: 20, NOMBRE: "Económico", VALOR:  0 },
        { ID: 22, NOMBRE: "Licencia Social", VALOR:  0 },
        { ID: 23, NOMBRE: "Político / Legal", VALOR: 0 },
    ];
    // Ordenamos los productos por FECHA
    productos = productos.sort((a, b) => {
        return new Date(b.FECHA) - new Date(a.FECHA);
    });

    let javascript_content = null;
    let kpi = 0;
    let kpi_average = 0;

    // Ontenemos las evaluaciones de intereses que correspondan a Guatemala para darle sentido a los valores de las categorias
    let productos_honduras = productos.filter(producto => producto.TIPO_PRODUCTO_ID == 4 && producto.PAIS_ID == 2);

    let productos_cadena = productos_honduras.filter(producto =>
        producto.RELACIONES_DOCUMENTO_INTERES.filter(relacion =>
            relacion.CATEGORIA_ID == 18
            || relacion.CATEGORIA_ID == 4
            || relacion.CATEGORIA_ID == 8
            ).length > 0);
    if(productos_cadena[0] != null && productos_cadena[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(productos_cadena[0].JAVASCRIPT);
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[0].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[0].VALOR = kpi;
        }
    }

    let productos_economicos = productos_honduras.filter(producto =>
        producto.RELACIONES_DOCUMENTO_INTERES.filter(relacion => 
            relacion.CATEGORIA_ID == 20
            || relacion.CATEGORIA_ID == 7
            || relacion.CATEGORIA_ID == 2
            ).length > 0);
    if(productos_economicos[0] != null && productos_economicos[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(productos_economicos[0].JAVASCRIPT); 
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[1].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[1].VALOR = kpi;
        }
    }
    
    let productos_licencia = productos_honduras.filter(producto =>
        producto.RELACIONES_DOCUMENTO_INTERES.filter(relacion =>
            relacion.CATEGORIA_ID == 22
            || relacion.CATEGORIA_ID == 9
            || relacion.CATEGORIA_ID == 5
            ).length > 0);
    if(productos_licencia[0] != null && productos_licencia[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(productos_licencia[0].JAVASCRIPT);
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[2].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[2].VALOR = kpi;
        }
    }

    let productos_politico = productos_honduras.filter(producto =>
        producto.RELACIONES_DOCUMENTO_INTERES.filter(relacion =>
            relacion.CATEGORIA_ID == 23
            || relacion.CATEGORIA_ID == 11
            || relacion.CATEGORIA_ID == 5
            ).length > 0);
    if(productos_politico[0] != null && productos_politico[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(productos_politico[0].JAVASCRIPT);
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[3].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[3].VALOR = kpi;
        }
    }


    return categorias;
}

function Panama(productos){
    let categorias = [
        { ID: 18, NOMBRE: "Cadena de Valor", VALOR: 0 },
        { ID: 20, NOMBRE: "Económico", VALOR: 0 },
        { ID: 22, NOMBRE: "Licencia Social", VALOR: 0 },
        { ID: 23, NOMBRE: "Político / Legal", VALOR: 0 },
    ];

    // Ordenamos los productos por FECHA
    productos = productos.sort((a, b) => {
        return new Date(b.FECHA) - new Date(a.FECHA);
    });

    let javascript_content = null;
    let kpi = 0;
    let kpi_average = 0;

    // Ontenemos las evaluaciones de intereses que correspondan a Guatemala para darle sentido a los valores de las categorias
    let productos_panama = productos.filter(producto => producto.TIPO_PRODUCTO_ID == 4 && producto.PAIS_ID == 4);

    let productos_cadena = productos_panama.filter(producto => 
        producto.RELACIONES_DOCUMENTO_INTERES.filter(relacion => 
            relacion.CATEGORIA_ID == 18
            || relacion.CATEGORIA_ID == 8
            || relacion.CATEGORIA_ID == 4
            ).length > 0);
    if(productos_cadena[0] != null && productos_cadena[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(productos_cadena[0].JAVASCRIPT);
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[0].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[0].VALOR = kpi;
        }
    }

    let productos_economicos = productos_panama.filter(producto =>
        producto.RELACIONES_DOCUMENTO_INTERES.filter(relacion =>
            relacion.CATEGORIA_ID == 20
            || relacion.CATEGORIA_ID == 7
            || relacion.CATEGORIA_ID == 2
            ).length > 0);
    if(productos_economicos[0] != null && productos_economicos[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(productos_economicos[0].JAVASCRIPT);
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[1].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[1].VALOR = kpi;
        }
    }

    let productos_licencia = productos_panama.filter(producto =>
        producto.RELACIONES_DOCUMENTO_INTERES.filter(relacion =>
            relacion.CATEGORIA_ID == 22
            || relacion.CATEGORIA_ID == 9
            || relacion.CATEGORIA_ID == 3
            ).length > 0);
    if(productos_licencia[0] != null && productos_licencia[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(productos_licencia[0].JAVASCRIPT);
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[2].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[2].VALOR = kpi;
        }
    }

    let productos_politico = productos_panama.filter(producto =>
        producto.RELACIONES_DOCUMENTO_INTERES.filter(relacion =>
            relacion.CATEGORIA_ID == 23
            || relacion.CATEGORIA_ID == 11
            || relacion.CATEGORIA_ID == 5
            ).length > 0);
    if(productos_politico[0] != null && productos_politico[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(productos_politico[0].JAVASCRIPT);
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[3].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[3].VALOR = kpi;
        }
    }

    return categorias;
}

function Region(productos){
    let categorias = [
        { ID: 19, NOMBRE: "Comercial", VALOR:  0 },
        { ID: 21, NOMBRE: "Geopolítica", VALOR:  0 },
    ];

    // Ordenamos los productos por FECHA
    productos = productos.sort((a, b) => {
        return new Date(b.FECHA) - new Date(a.FECHA);
    });

    let javascript_content = null;
    let kpi = 0;
    let kpi_average = 0;

    // Ontenemos las evaluaciones de intereses que correspondan a Guatemala para darle sentido a los valores de las categorias
    let productos_region = productos.filter(producto => producto.TIPO_PRODUCTO_ID == 4 && producto.PAIS_ID == 25);
    
    let productos_comercial = productos_region.filter(producto => producto.RELACIONES_DOCUMENTO_INTERES.filter(relacion => relacion.CATEGORIA_ID == 6).length > 0);
    if(productos_comercial[0] != null && productos_comercial[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(productos_comercial[0].JAVASCRIPT); 
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[0].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[0].VALOR = kpi;
        }
    }

    let productos_geopolitica = productos_region.filter(producto => producto.RELACIONES_DOCUMENTO_INTERES.filter(relacion => relacion.CATEGORIA_ID == 10).length > 0);
    if(productos_geopolitica[0] != null && productos_geopolitica[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(productos_geopolitica[0].JAVASCRIPT); 
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[1].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[1].VALOR = kpi;
        }
    }
    return categorias;
}

function Belice(productos){
    let categorias = [
        { ID: 18, NOMBRE: "Cadena de Valor", VALOR: 0 },
        { ID: 20, NOMBRE: "Económico", VALOR: 0 },
        { ID: 22, NOMBRE: "Licencia Social", VALOR: 0 },
        { ID: 23, NOMBRE: "Político / Legal", VALOR: 0 },
    ];

    // Ordenamos los productos por FECHA
    productos = productos.sort((a, b) => {
        return new Date(b.FECHA) - new Date(a.FECHA);
    });

    let javascript_content = null;
    let kpi = 0;
    let kpi_average = 0;

    // Ontenemos las evaluaciones de intereses que correspondan a Guatemala para darle sentido a los valores de las categorias
    let productos_belice = productos.filter(producto => producto.TIPO_PRODUCTO_ID == 4 && producto.PAIS_ID == 3);

    let cadena_valor = productos_belice.filter(producto => 
        producto.RELACIONES_DOCUMENTO_INTERES.filter(
            relacion => relacion.CATEGORIA_ID == 18
            || relacion.CATEGORIA_ID == 8
            || relacion.CATEGORIA_ID == 2
            ).length > 0);
    if(cadena_valor[0] != null && cadena_valor[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(cadena_valor[0].JAVASCRIPT); 
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[0].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[0].VALOR = kpi;
        }
    }

    let productos_economicos = productos_belice.filter(producto =>
        producto.RELACIONES_DOCUMENTO_INTERES.filter(
            relacion => relacion.CATEGORIA_ID == 20
            || relacion.CATEGORIA_ID == 7
            || relacion.CATEGORIA_ID == 1
            ).length > 0);
    if(productos_economicos[0] != null && productos_economicos[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(productos_economicos[0].JAVASCRIPT);
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[1].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[1].VALOR = kpi;
        }
    }

    let productos_licencia = productos_belice.filter(producto =>
        producto.RELACIONES_DOCUMENTO_INTERES.filter(
            relacion => relacion.CATEGORIA_ID == 22
            || relacion.CATEGORIA_ID == 9
            || relacion.CATEGORIA_ID == 5
            ).length > 0);
    if(productos_licencia[0] != null && productos_licencia[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(productos_licencia[0].JAVASCRIPT);
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[2].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[2].VALOR = kpi;
        }
    }

    let productos_politico = productos_belice.filter(producto =>
        producto.RELACIONES_DOCUMENTO_INTERES.filter(
            relacion => relacion.CATEGORIA_ID == 23
            || relacion.CATEGORIA_ID == 11
            || relacion.CATEGORIA_ID == 5
            ).length > 0);
    if(productos_politico[0] != null && productos_politico[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(productos_politico[0].JAVASCRIPT);
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[3].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[3].VALOR = kpi;
        }
    }

    return categorias;
}

function CostaRica(productos){
    let categorias = [
        { ID: 18, NOMBRE: "Cadena de Valor", VALOR: 0 },
        { ID: 20, NOMBRE: "Económico", VALOR: 0 },
        { ID: 22, NOMBRE: "Licencia Social", VALOR: 0 },
        { ID: 23, NOMBRE: "Político / Legal", VALOR: 0 },
    ];

    // Ordenamos los productos por FECHA
    productos = productos.sort((a, b) => {
        return new Date(b.FECHA) - new Date(a.FECHA);
    });

    let javascript_content = null;
    let kpi = 0;
    let kpi_average = 0;

    // Ontenemos las evaluaciones de intereses que correspondan a Guatemala para darle sentido a los valores de las categorias
    let productos_costa_rica = productos.filter(producto => producto.TIPO_PRODUCTO_ID == 4 && producto.PAIS_ID == 5);

    let cadena_valor = productos_costa_rica.filter(producto => 
        producto.RELACIONES_DOCUMENTO_INTERES.filter(
            relacion => relacion.CATEGORIA_ID == 18
            || relacion.CATEGORIA_ID == 8
            || relacion.CATEGORIA_ID == 2
            ).length > 0);
    if(cadena_valor[0] != null && cadena_valor[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(cadena_valor[0].JAVASCRIPT); 
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[0].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[0].VALOR = kpi;
        }
    }

    let productos_economicos = productos_costa_rica.filter(producto =>
        producto.RELACIONES_DOCUMENTO_INTERES.filter(
            relacion => relacion.CATEGORIA_ID == 20
            || relacion.CATEGORIA_ID == 7
            || relacion.CATEGORIA_ID == 1
            ).length > 0);
    if(productos_economicos[0] != null && productos_economicos[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(productos_economicos[0].JAVASCRIPT);
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[1].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[1].VALOR = kpi;
        }
    }

    let productos_licencia = productos_costa_rica.filter(producto =>
        producto.RELACIONES_DOCUMENTO_INTERES.filter(
            relacion => relacion.CATEGORIA_ID == 22
            || relacion.CATEGORIA_ID == 9
            || relacion.CATEGORIA_ID == 5
            ).length > 0);
    if(productos_licencia[0] != null && productos_licencia[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(productos_licencia[0].JAVASCRIPT);
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[2].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[2].VALOR = kpi;
        }
    }

    let productos_politico = productos_costa_rica.filter(producto =>
        producto.RELACIONES_DOCUMENTO_INTERES.filter(
            relacion => relacion.CATEGORIA_ID == 23
            || relacion.CATEGORIA_ID == 11
            || relacion.CATEGORIA_ID == 5
            ).length > 0);
    if(productos_politico[0] != null && productos_politico[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(productos_politico[0].JAVASCRIPT);
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[3].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[3].VALOR = kpi;
        }
    }

    return categorias;
}

function ElSalvador(productos){
    let categorias = [
        { ID: 18, NOMBRE: "Cadena de Valor", VALOR: 0 },
        { ID: 20, NOMBRE: "Económico", VALOR: 0 },
        { ID: 22, NOMBRE: "Licencia Social", VALOR: 0 },
        { ID: 23, NOMBRE: "Político / Legal", VALOR: 0 },
    ];

    // Ordenamos los productos por FECHA
    productos = productos.sort((a, b) => {
        return new Date(b.FECHA) - new Date(a.FECHA);
    });

    let javascript_content = null;
    let kpi = 0;
    let kpi_average = 0;

    // Ontenemos las evaluaciones de intereses que correspondan a Guatemala para darle sentido a los valores de las categorias
    let productos_el_salvador = productos.filter(producto => producto.TIPO_PRODUCTO_ID == 4 && producto.PAIS_ID == 6);

    let cadena_valor = productos_el_salvador.filter(producto => 
        producto.RELACIONES_DOCUMENTO_INTERES.filter(
            relacion => relacion.CATEGORIA_ID == 18
            || relacion.CATEGORIA_ID == 8
            || relacion.CATEGORIA_ID == 2
            ).length > 0);
    if(cadena_valor[0] != null && cadena_valor[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(cadena_valor[0].JAVASCRIPT); 
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[0].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[0].VALOR = kpi;
        }
    }

    let productos_economicos = productos_el_salvador.filter(producto =>
        producto.RELACIONES_DOCUMENTO_INTERES.filter(
            relacion => relacion.CATEGORIA_ID == 20
            || relacion.CATEGORIA_ID == 7
            || relacion.CATEGORIA_ID == 1
            ).length > 0);
    if(productos_economicos[0] != null && productos_economicos[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(productos_economicos[0].JAVASCRIPT);
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[1].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[1].VALOR = kpi;
        }
    }

    let productos_licencia = productos_el_salvador.filter(producto =>
        producto.RELACIONES_DOCUMENTO_INTERES.filter(
            relacion => relacion.CATEGORIA_ID == 22
            || relacion.CATEGORIA_ID == 9
            || relacion.CATEGORIA_ID == 5
            ).length > 0);
    if(productos_licencia[0] != null && productos_licencia[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(productos_licencia[0].JAVASCRIPT);
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[2].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[2].VALOR = kpi;
        }
    }

    let productos_politico = productos_el_salvador.filter(producto =>
        producto.RELACIONES_DOCUMENTO_INTERES.filter(
            relacion => relacion.CATEGORIA_ID == 23
            || relacion.CATEGORIA_ID == 11
            || relacion.CATEGORIA_ID == 5
            ).length > 0);
    if(productos_politico[0] != null && productos_politico[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(productos_politico[0].JAVASCRIPT);
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[3].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[3].VALOR = kpi;
        }
    }

    return categorias;
}

function Colombia(productos){
    let categorias = [
        { ID: 18, NOMBRE: "Cadena de Valor", VALOR: 0 },
        { ID: 20, NOMBRE: "Económico", VALOR: 0 },
        { ID: 22, NOMBRE: "Licencia Social", VALOR: 0 },
        { ID: 23, NOMBRE: "Político / Legal", VALOR: 0 },
    ];

    // Ordenamos los productos por FECHA
    productos = productos.sort((a, b) => {
        return new Date(b.FECHA) - new Date(a.FECHA);
    });

    let javascript_content = null;
    let kpi = 0;
    let kpi_average = 0;

    // Ontenemos las evaluaciones de intereses que correspondan a Guatemala para darle sentido a los valores de las categorias
    let productos_colombia = productos.filter(producto => producto.TIPO_PRODUCTO_ID == 4 && producto.PAIS_ID == 7);

    let cadena_valor = productos_colombia.filter(producto => 
        producto.RELACIONES_DOCUMENTO_INTERES.filter(
            relacion => relacion.CATEGORIA_ID == 18
            || relacion.CATEGORIA_ID == 8
            || relacion.CATEGORIA_ID == 2
            ).length > 0);
    if(cadena_valor[0] != null && cadena_valor[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(cadena_valor[0].JAVASCRIPT); 
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[0].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[0].VALOR = kpi;
        }
    }

    let productos_economicos = productos_colombia.filter(producto =>
        producto.RELACIONES_DOCUMENTO_INTERES.filter(
            relacion => relacion.CATEGORIA_ID == 20
            || relacion.CATEGORIA_ID == 7
            || relacion.CATEGORIA_ID == 1
            ).length > 0);
    if(productos_economicos[0] != null && productos_economicos[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(productos_economicos[0].JAVASCRIPT);
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[1].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[1].VALOR = kpi;
        }
    }

    let productos_licencia = productos_colombia.filter(producto =>
        producto.RELACIONES_DOCUMENTO_INTERES.filter(
            relacion => relacion.CATEGORIA_ID == 22
            || relacion.CATEGORIA_ID == 9
            || relacion.CATEGORIA_ID == 5
            ).length > 0);
    if(productos_licencia[0] != null && productos_licencia[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(productos_licencia[0].JAVASCRIPT);
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[2].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[2].VALOR = kpi;
        }
    }

    let productos_politico = productos_colombia.filter(producto =>
        producto.RELACIONES_DOCUMENTO_INTERES.filter(
            relacion => relacion.CATEGORIA_ID == 23
            || relacion.CATEGORIA_ID == 11
            || relacion.CATEGORIA_ID == 5
            ).length > 0);
    if(productos_politico[0] != null && productos_politico[0].JAVASCRIPT != null){
        javascript_content = JSON.parse(productos_politico[0].JAVASCRIPT);
        if(javascript_content.ponderacion.length > 1){
            kpi = javascript_content.ponderacion;
            kpi_average = kpi.reduce((a, b) => a + b, 0) / kpi.length;
            categorias[3].VALOR = kpi_average;
        } else {
            kpi = javascript_content.ponderacion[0];
            categorias[3].VALOR = kpi;
        }
    }

    return categorias;
}

function getCategoria(pais_id, productos){
    switch(pais_id){
        case 1:
            return Guatemala(productos);
        case 2:
            return Honduras(productos);
        case 3:
            return Belice(productos);
        case 4:
            return Panama(productos);
        case 5:
            return CostaRica(productos);
        case 6:
            return ElSalvador(productos);
        case 7:
            return Colombia(productos);
        case 25:
            return Region(productos);
        default:
            return categorias;
    }
}

export default getCategoria;