import React from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const SideBar = ({state, setState}) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        window.location.href = 'https://ayplatam.com/Auth/Logout';
    };

    const handlePassword = () => {
        setState({
            ...state,
            view_side_bar: false,
        });
        return navigate("/password");
    };

    return (
        <Container className="sidebar bg-sidebar">
        <Row>
            <Col className='mb-1 mt-4'>
                <h2 className="text-white">Bienvenido {state.usuario?.APODO}</h2>
            </Col>
        </Row>
        <hr/>
        <Row>
            <Col>
                <p>Tu sesión expira en: {state.expiracion}</p>
            </Col>
        </Row>
        <hr/>
        <Row>
            <Col>
                <Button className="btn-logout" onClick={handlePassword}>
                    Cambiar Contraseña
                </Button>
            </Col>
        </Row>
        <hr/>
        <Row>
            <Col className='mb-4'>
                <Button className="btn-logout" onClick={handleLogout}>
                    <FontAwesomeIcon icon={faSignOut} />
                    &nbsp;
                    Cerrar Sesión
                </Button>
            </Col>
        </Row>
        <Row>
            <Col className="mb-4">
                <p className="text-center">
                    &copy; - 2023 AyPLatam
                </p>
            </Col>
        </Row>
        </Container>
    );
}

export default SideBar;